import React, { ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Theme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import CustomButton from '../../../button/button';
import { mainRadius } from '../../../../themes/theme';
import UploadFiles from '../../../upload/uploadfiles';
import { AumUploadService } from '../../../../services/aumimport';

export interface FilesState {
    accepted: boolean,
    file: File
  }
  
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '24px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: mainRadius,
      transition: 'all 0.2s ease-in-out',
      opacity: '0.9',
      '&:hover': {
        opacity: '1',
        zIndex: '9',
        boxShadow: '0 8px 30px -10px rgba(0, 0, 0, 0.4)',
        transition: 'all 0.2s ease-in-out'
      }
    }
  }));

const authorizedTypes = ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xls', '.xlt', '.xml', '.xlam', '.xla', '.xlw', '.xlr'];

function AumImportComponent() : ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);

  const keycloak = useKeycloak();
  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    if (keycloak.initialized) {
      keycloak.keycloak.loadUserInfo()
        // keycloak.keycloak.idToken will eventually replace the username in near future because given username shouldn't be trusted as the server should force the username based on the token
        // keycloak LoadUserInfo returns a {} whereas we receive an object with .name so it cannot be typed and had to use 'as' Type Assertion to force the typing
        .then(res => setUsername((res as { name: string}).name));
    }
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
            Import Asset Under Management
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/admin/aum'>
          <CustomButton variant='contained'>Show all imported AUM</CustomButton>
        </Link>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant='h2'>Or</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UploadFiles
                service={new AumUploadService(username)}
                update={() => undefined}
                authorizedTypes={authorizedTypes}
                uploadButtonLabel='Click here to upload AUM'
                baseUrl='/admin/aum'
                multiple
                dispUploaded
                childBeforeBtn
              >
                <Typography variant='h2'>Drag and drop here the file to import or</Typography>
              </UploadFiles>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AumImportComponent;

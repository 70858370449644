import React, { ReactElement, useMemo } from "react";
import { dataContext } from "../../../../hooks/datatabs";
import AuditTrail from "../../../../components/audittrail/audittrail";

function DdAuditTrail(): ReactElement {
  const data = dataContext();
  const entityId = useMemo(() => data?.dueDiligence?._id || null, [data?.dueDiligence?._id]);

  return (
    <>
      { data?.dueDiligence?._id && entityId &&
        <AuditTrail entityType='dds' id={entityId || ''} />
      }
    </>
  );
}

export default DdAuditTrail;
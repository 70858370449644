import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Theme } from '@mui/material';
import { AumImportError, AumImportErrorLevel } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import { ddAumContext } from '../../../hooks/datatabs';
import CustomTable, { Column } from '../../table/table';
import { makeColoredRow } from '../import/importlist';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  failedRow: makeColoredRow(theme.palette.error.light),
  warningRow: makeColoredRow(theme.palette.warning.light),
  infoRow: makeColoredRow(theme.palette.info.light),
  completedRow: makeColoredRow(theme.palette.success.light),
  successRow: makeColoredRow(theme.palette.success.light)
}));

function DdAumParsingErrors(): ReactElement {
  const ddAum = ddAumContext();
  const classes = useStyles({});

  const columns: Array<Column> = [
    { id: 'level', label: 'Level', minWidth: 'min-content' },
    { id: 'code', label: 'Code', minWidth: 'min-content' },
    { id: 'message', label: 'Message', minWidth: 'min-content' },
    { id: 'stacktrace', label: 'Stacktrace', minWidth: 'min-content' },
    { id: 'context', label: 'Context', minWidth: 'min-content' }
  ];
  
  const rows = ddAum?.parsingErrors.map((event: AumImportError) => {
    let className : string | undefined;
    if (event.level === AumImportErrorLevel.ERROR) {
      className = classes.failedRow;
    } else if (event.level === AumImportErrorLevel.WARNING) {
      className = classes.warningRow;
    } else if (event.level === AumImportErrorLevel.INFO) {
      className = classes.infoRow;
    }
  
    return ({
      id: `${event.level}-${event.code}`,
      className,
      value: columns.map(column => (
        <div key={column.id}>
          {column.id === 'level' && event.level}
          {column.id === 'code' && event.code}
          {column.id === 'message' && event.message}
          {column.id === 'stacktrace' && event.stacktrace}
          {column.id === 'context' && event.context &&
            <Grid container spacing={1}>
              <Grid item xs={12}>
                excelTab: {event.context.excelTab}
              </Grid>
              <Grid item xs={12}>
                rowNum: {event.context.rowNum}
              </Grid>
            </Grid>
          }
        </div>
      ))
    });
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h3>Errors / Warnings</h3>
            { (ddAum?.parsingErrors?.length || 0) > 0 &&
              <CustomTable columns={columns} rows={rows} size='small' />
            }
            { ddAum?.parsingErrors?.length === 0 && <>No parsing errors or warnings...</>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DdAumParsingErrors;

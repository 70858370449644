import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import { Grid, TableBody, TableRow, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDownwardRounded } from '@mui/icons-material';
import { Column } from './table';
import CustomButton from '../button/button';
import { FilterParamsManager } from '../entity/list';
import { mainRadius } from '../../themes/theme';
import ScrollTopBtn from '../layout/tools/scrolltop';

export const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    padding: '12px 4px',
    borderBottom: 'none',
    zIndex: 10,
    left: 'inherit !important',
    "&:first-of-type": {
      borderTopLeftRadius: mainRadius,
      borderBottomLeftRadius: mainRadius
    },
    "&:last-of-type": {
      borderTopRightRadius: mainRadius,
      borderBottomRightRadius: mainRadius
    }
  },
  tableRow: {
    backgroundColor: theme.palette.background.default,
    width: '100%'
  },
  arrow: {
    '&:hover': {
      opacity: '1 !important'
    }
  }
}));

const columnDisabled = [
  'owners',
  'totalFund',
  'investMgr',
  'nbOfElement',
  'currentPhase',
  'reports'
];

function CustomHead(columns: Array<Column>, paramsSort?: FilterParamsManager): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [displaySort, setDisplaySort] = useState<string | undefined>(paramsSort?.getFilters()[0]?.id);
  const [rotate, setRotate] = useState(paramsSort?.getFilters()[0]?.value === 'dsc');
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const scrollHandler = () => {
    if (tableRef.current && tableRef.current?.getBoundingClientRect().top < 0 && !isVisible) {
      setIsVisible(true);
    } else if (tableRef.current && tableRef.current?.getBoundingClientRect().top >= 0) {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    tableRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' });
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);

    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  useEffect(() => {
    if (paramsSort) {
      const filters = paramsSort.getFilters();

      if (filters.length > 0) {
        setDisplaySort(filters[0].id);
        setRotate(filters[0].value !== 'asc');
      }
    }
  }, [paramsSort]);

  const handleOnClick = (id: string | undefined) => {
    if (paramsSort && id && !columnDisabled.includes(id)) {
      if (id === displaySort) {
        setRotate(!rotate);
        paramsSort.onFilterChanged(id, !rotate ? 'dsc' : 'asc');
      } else {
        setRotate(false);
        setDisplaySort(id);
        if (displaySort) {
          paramsSort.onFilterRemoved(displaySort);
        }
        paramsSort.onFilterChanged(id, 'asc');
      }
    }
  };

  return (
    <>
      <TableHead
        ref={tableRef}
        style={
          {
            borderTopRightRadius: mainRadius,
            borderTopLeftRadius: mainRadius
          }
        }
      >
        <TableRow className={classes.tableRow}>
          {columns.map(column => (
            <TableCell
              key={column.id}
              align={column.align || 'left'}
              className={classes.tableHead}
              style={{
                minWidth: column.minWidth,
                width: column.id === 'avatar' || column.id === 'selected' ? '40px' : 'auto'
              }}
            >
              <CustomButton
                variant='text'
                disableRipple
                style={{
                  color: 'gray',
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  cursor: paramsSort && !columnDisabled.includes(`${column.id}`) ? 'pointer' : 'inherit'
                }}
                small
                onClick={() => handleOnClick(column.id)}
              >
                <Grid container spacing={1} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography
                      variant='h3'
                      style={column.align === 'center' ? {
                        marginLeft: '18px'
                      } :
                        undefined
                      }
                    >
                      {column.label}
                    </Typography>
                  </Grid>
                  { paramsSort && !columnDisabled.includes(`${column.id}`) &&
                    <Grid item>
                      <ArrowDownwardRounded
                        style={{
                          opacity: displaySort === column.id ? 1 : 0.3,
                          width: '16px',
                          marginTop: '8px',
                          transform: `rotate(${rotate && displaySort === column.id ? '180' : '0'}deg)`
                        }}
                        className={classes.arrow}
                      />
                    </Grid>
                  }
                </Grid>
              </CustomButton>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {isVisible &&
        <TableBody>
          <TableRow>
            <TableCell>
              <ScrollTopBtn isVisible scrollToTop={scrollToTop}/>
            </TableCell>
          </TableRow>
        </TableBody>
      }
    </>
  );
}

export default CustomHead;

import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { Link } from 'react-router-dom';
import { OpenInNewRounded } from '@mui/icons-material';
import { NextReview } from '@deecision/deeligenz-interfaces';
import NextReviewChip from './nextreviewschip';

interface NextReviewsProps {
  nextReviews?: NextReview[],
  showRelatedDd?: boolean,
  reviewsStopped?: boolean,
  variant?: Variant,
  displayMinimalist?: boolean,
  hideTooltipChipDateInformation?: boolean,
  textOnly?: boolean,
  alignLeft?: boolean,
  minContent?: boolean
}

function NextReviews(props: NextReviewsProps): ReactElement {

  return (
    <Grid container width={props.minContent ? 'min-content' : undefined} spacing={1} sx={{ paddingTop: '-8px !important' }}>
      {props.nextReviews && props.nextReviews.length > 0 ? (
        props.nextReviews.map(review => (
          <Grid
            container
            item
            xs={12}
            direction='row'
            alignItems='center'
            justifyContent={props.alignLeft ? undefined : 'center'}
          >
            <Grid item>
              <NextReviewChip review={review} hideTooltipChipDateInformation={props.hideTooltipChipDateInformation}/>
            </Grid>
            <Grid item>
              <Typography variant={props.variant || 'body1'} fontWeight={500}>
                {!props.displayMinimalist && (
                  `: ${review.reviewType}
                    ${review.ddTypes.length > 0 ? ` (${review.ddTypes[0]})` : ''}`
                )}
              </Typography>
            </Grid>
            {props.showRelatedDd && review.relatedDdId && (
              <Grid item>
                <Link to={`/dds/${review.relatedDdId}/summary`}>
                  <OpenInNewRounded style={{ width: '10px', verticalAlign: 'middle' }} />
                </Link>
              </Grid>
            )}
          </Grid>
        ))
      ) : ( // Fallback display when there is no nextReviews
        <Grid container item xs={12} alignItems='center' justifyContent='center'>
          <Typography variant={props.variant || 'body1'} fontWeight={500}>
            {props.reviewsStopped ? 'Coverage stopped' : 'N/A'}
          </Typography>
        </Grid>
      )}
    </Grid>
  
  );
}

export default NextReviews;

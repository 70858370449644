import { AumContributor } from "@deecision/deeligenz-interfaces";
import AumContributorsService from "../../../services/aumcontributors";
import { EntityListDataProvider } from "../../entity/list";
import { SearchParamsAccess } from "../../params";

class AumDataProvider extends EntityListDataProvider<AumContributor> {
  constructor( entityType: string, searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number) {
    super(searchParamsAccess, new AumContributorsService(entityType), itemsPerPageMin);
  }
}

export default AumDataProvider;
import React, { ReactElement, useMemo } from "react";
import { strategyContext } from "../../../../hooks/datatabs";
import AuditTrail from "../../../../components/audittrail/audittrail";

function StrategyAuditTrail(): ReactElement {
  const data = strategyContext();
  const entityId = useMemo(() => data?._id || null, [data?._id]);

  return (
    <>
      { data?._id && entityId &&
        <AuditTrail entityType='strategy' id={entityId || 'no id'} />
      }
    </>
  );
}

export default StrategyAuditTrail;
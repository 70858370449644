import React, { ReactElement, useState } from 'react';
import { Grid, Paper, Snackbar, Typography, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../button/button';
import ServiceError from '../../../services/errors';
import { AumSelectionContentProps } from './type';

function AumSelectionFooter(props: Omit<AumSelectionContentProps, 'setSelectedIds'>): ReactElement {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleSave = () => {
    props.aumService.setAUM([
      ...props.processingErrorsSelectedIds,
      ...props.data.filter(myData => props.selectedIds.find(obj => obj.identifier === myData.identifier))
    ])
      .then(() => {
        // Has to setTimeout of 0.3s before updating entity page by fetching new data to get new review date due to asynchronism between server and async worker that handles nextReviews
        setTimeout(() => {
          props.update();
          props.handleClose();
        }, 300);
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <Grid item xs={12}>
        {props.selectedIds.length > 0 ?
          props.selectedIds.map(obj => (
            <Paper
              key={obj.identifier as string}
              elevation={0}
              style={{
                marginRight: '8px',
                marginBottom: '8px',
                padding: '8px',
                backgroundColor: '#d9e4f4',
                borderRadius: '16px',
                display: 'inline-block'
              }}
            >
              <Typography variant='h3'>{obj.label || ''}</Typography>
              <Typography style={{ fontSize: '12px' }}>{obj.identifier || ''}</Typography>
            </Paper>
          ))
          :
          <Typography variant='h3'>
            {t('dds.tabs.parameters.aums.noAumSelected')}
          </Typography>}
      </Grid>
      <Grid item xs={12}>
        <CustomButton
          style={{ marginBottom: '14px' }}
          onClick={handleSave}
          variant='contained'
          color='primary'
          disabled={!props.selectedIds.length}
          fullWidth
        >
          {t('dds.tabs.parameters.aums.addSelection')}
        </CustomButton>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AumSelectionFooter;
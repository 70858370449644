import React, { ReactElement, useEffect, useState } from 'react';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderTitle from '../../../components/modularcomponents/header/headertitle';
import { DataDueDiligence, Nullable, PossibleAction } from '../../../types/types';
import HeaderProperty from '../../../components/modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../../components/modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../../components/modularcomponents/header/headerpropertyvalue';
import { DispachDdOnBlock } from '../listing/datadisplay';
import HeaderContainer from '../../../components/modularcomponents/header/headercontainer';
import { DdService } from '../../../services/dds';
import HeaderItems from '../../../components/modularcomponents/header/headeritems';
import HeaderItem from '../../../components/modularcomponents/header/headeritem';
import HeaderRating from '../../../components/headerdata/headerrating';
import HeaderButtons from '../../../components/modularcomponents/header/headerbuttons';
import HeaderActivities from '../../../components/modularcomponents/header/headeractivities';
import HeaderPhase from '../../../components/modularcomponents/header/headerphase';
import HeaderSummary from '../../../components/modularcomponents/header/headersummary';
import HeaderGlobalProgress from '../../../components/modularcomponents/header/headerglobalprogress';
import HeaderAUM from '../../../components/modularcomponents/header/headeraum';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';

function DueDiligenceHeader({ entity } : {entity: Nullable<DataDueDiligence>}): ReactElement {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const [expand, setExpand] = useState(true);
  const title: string = entity ? `${  entity.auditedEntity.name  } - ${  entity.type   }` : '';
  const actionList: PossibleAction[] = [];

  useEffect(() => {
    setUpdate(value => !value);
  }, [entity]);

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <>
      { entity &&
        <>
          <HeaderContainer expanded={expand} setExpanded={setExpand}>
            <HeaderSummary toggleExpand={toggleExpand}>
              <HeaderTitle toggleExpand={toggleExpand} type='dueDiligence' name={title} /* date={'Date of analysis : ' + entity.finalDate} */>
                <Grid item>
                  {entity.status ? <Chip label={capitalizeFirstLetter(entity.status)} style={{ marginLeft: '12px', marginTop: '4px' }} /> : undefined}
                </Grid>
                <>
                  {entity.tags && entity.tags?.length > 0 && entity.tags?.map(tag => (
                    tag &&
                    <Grid item key={tag.value}>
                      <Tooltip arrow title={tag.family}>
                        <Chip label={capitalizeFirstLetter(tag.value)} style={{ marginLeft: '0px', marginTop: '4px' }} />
                      </Tooltip>
                    </Grid>
                  ))}
                </>
              </HeaderTitle>
              <HeaderButtons id={entity._id} service={new DdService()} update={update} actionList={actionList}/>
            </HeaderSummary>
            <HeaderItems>
              {entity.lifecycle && entity.lifecycle.phases.length > 0 ?
                <HeaderGlobalProgress lifecycle={entity.lifecycle} /> :
                <></>
              }
              <HeaderItem>
                <Grid item xs={12}>
                  <Typography variant='body2' style={{ width: 'min-content', whiteSpace: 'nowrap', paddingBottom: '4px' }}>{t('dds.header.blocks.ddsOn')}</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '-16px' }}>
                  <DispachDdOnBlock auditedEntity={entity.auditedEntity} />
                </Grid>
                <HeaderProperty>
                  <HeaderAUM aum={entity.auditedEntity.aum} />
                </HeaderProperty>

                <HeaderProperty>
                  <HeaderPropertyName name={t('dds.header.blocks.type')} />
                  <HeaderPropertyValue>{entity.type}</HeaderPropertyValue>
                </HeaderProperty>

                <HeaderProperty>
                  <HeaderPropertyName name={`${t('dds.dates.startDate')} `} />
                  <HeaderPropertyValue>{entity.startDate}</HeaderPropertyValue>
                  { entity.expectedValidationDate ?
                    <>
                      <HeaderPropertyName name={`${t('dds.dates.expectedValidationDate')} `} />
                      <HeaderPropertyValue>{entity.expectedValidationDate}</HeaderPropertyValue>
                    </> :
                    <>
                      <HeaderPropertyName name={`${t('dds.dates.validateDate')} `} />
                      <HeaderPropertyValue>{entity.validationDate}</HeaderPropertyValue>
                    </>
                  }
                </HeaderProperty>
              </HeaderItem>
              <HeaderItem>

                {entity.lifecycle ?
                  <>
                    <HeaderProperty>
                      <HeaderPropertyName name={`${t('dds.header.blocks.currentPhase')}  `} />
                      <HeaderPhase lifeCycle={entity.lifecycle} />
                    </HeaderProperty>

                    <HeaderProperty>
                      <HeaderPropertyName name={`${t('dds.header.blocks.currentActivities')}  `} />
                      <HeaderActivities lifeCycle={entity.lifecycle} />
                    </HeaderProperty>
                  </> :
                  <></>
                }
              </HeaderItem>
              <HeaderItem>
                <HeaderRating
                  rating={entity.ratings}
                  nextReviews={entity.auditedEntity.nextReviews}
                  entityType={entity.status === 'started' ? entity.type : undefined}
                />
              </HeaderItem>
            </HeaderItems>
          </HeaderContainer>
        </>
      }
    </>
  );
}

export default DueDiligenceHeader;

import React, { ReactElement, useMemo } from "react";
import { investmgrContext } from "../../../../hooks/datatabs";
import AuditTrail from "../../../../components/audittrail/audittrail";

function AssetMgrAuditTrail(): ReactElement {
  const data = investmgrContext();
  const entityId = useMemo(() => data?._id || null, [data?._id]);

  return (
    <>
      { data?._id && entityId &&
        <AuditTrail entityType='assetMgr' id={entityId || ''} />
      }
    </>
  );
}

export default AssetMgrAuditTrail;
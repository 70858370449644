import React, { ReactElement, useState } from 'react';
import { PossibleAction } from '@deecision/deeligenz-interfaces';
import { AumImport } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import HeaderTitle from '../../modularcomponents/header/headertitle';
import HeaderProperty from '../../modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../modularcomponents/header/headerpropertyvalue';
import HeaderContainer from '../../modularcomponents/header/headercontainer';
import HeaderItems from '../../modularcomponents/header/headeritems';
import HeaderItem from '../../modularcomponents/header/headeritem';
import HeaderButtons from '../../modularcomponents/header/headerbuttons';
import HeaderSummary from '../../modularcomponents/header/headersummary';
import AumImportService from '../../../services/aumimport';

function DdAumHeader(props: {ddAum: AumImport}): ReactElement {
  const [expand, setExpand] = useState(false);
  const actionList: PossibleAction[] = (props.ddAum.parsingErrors.length > 0 || props.ddAum.status !== 'done')  ?
    [] :
    [{
      id: 'activate',
      label: 'Activate',
      enabled: true,
      confirmText: `Do you want to activate ${props.ddAum.originalFileName}?`,
      call: {
        action: 'Activate',
        params: {
          id: props.ddAum._id
        }
      },
      type: props.ddAum.parsingErrors.length > 0 || props.ddAum.processingErrors.length > 0 ? 'error' : 'success'
    }];

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <HeaderContainer expanded={expand} setExpanded={setExpand}>
      <HeaderSummary toggleExpand={toggleExpand}>
        <HeaderTitle toggleExpand={toggleExpand} type='' name={props.ddAum.originalFileName} />
        <HeaderButtons id={props.ddAum._id} service={new AumImportService()} actionList={actionList} />
      </HeaderSummary>
      <HeaderItems>
        <HeaderItem>
          <HeaderProperty>
            <HeaderPropertyName name='Filename' />
            <HeaderPropertyValue>
              {props.ddAum.originalFileName}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Status' />
            <HeaderPropertyValue>
              {props.ddAum.status}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Active' />
            <HeaderPropertyValue>
              {props.ddAum.active.toString()}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Start Date' />
            <HeaderPropertyValue>
              {props.ddAum.startTime.split('T')[0]}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Imported By' />
            <HeaderPropertyValue>
              {props.ddAum.importedBy}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Parsing Errors' />
            <HeaderPropertyValue>
              {(props.ddAum.parsingErrors?.length || 0).toString()}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Processing Errors' />
            <HeaderPropertyValue>
              {(props.ddAum.processingErrors?.length || 0).toString()}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Number Of Asset Managers' />
            <HeaderPropertyValue>
              {props.ddAum.stats?.nbAssetMgrs.toString()}
            </HeaderPropertyValue>
          </HeaderProperty>

          <HeaderProperty>
            <HeaderPropertyName name='Number Of Funds' />
            <HeaderPropertyValue>
              {props.ddAum.stats?.nbFunds.toString()}
            </HeaderPropertyValue>
          </HeaderProperty>

          {/* <HeaderProperty>
            <HeaderPropertyName name='Value' />
            <HeaderPropertyValue>
              {aumValue}
            </HeaderPropertyValue>
          </HeaderProperty> */}
        </HeaderItem>
      </HeaderItems>
    </HeaderContainer>
  );
}

export default DdAumHeader;

import { ActionLog } from "@deecision/deeligenz-interfaces";
import AuditTrailService from "../../services/AuditTrailService";
import DdAuditTrailService from "../../services/DdAuditTrailService";
import { EntityListDataProvider } from "../entity/list";
import { SearchParamsAccess } from "../params";

class AuditTrailDataProvider extends EntityListDataProvider<ActionLog> {
  constructor( entityType: string, searchParamsAccess: SearchParamsAccess, id: string, itemsPerPageMin?: number) {
    super(searchParamsAccess, entityType === 'dds' ? new DdAuditTrailService(id) : new AuditTrailService(entityType, id), itemsPerPageMin);
  }
}

export default AuditTrailDataProvider;
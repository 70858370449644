import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, Tooltip, Typography, Alert, Theme, Stack, Box, useTheme } from '@mui/material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import makeStyles from '@mui/styles/makeStyles';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CustomTable, { Column } from '../table/table';
import { CustomIconButton } from '../button/button';
import { AumContributor } from '../../types/types';
import AumDataModal from './dialog';
import { roundValue } from '../../utils/computations';
import AumService from '../../services/aum';
import ServiceError from '../../services/errors';
import { makeColoredRow } from '../admin/import/importlist';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  disabledRow: makeColoredRow(theme.palette.grey[700])
}));

function AumsTable(props: {aumService: AumService, list: AumContributor[], aumType: string, type: string, update: () => void, readonly?: boolean}): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles({});
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleRemoveAum = (myAum: AumContributor) => {
    props.aumService.setAUM(props.list.filter(contributor => contributor.identifier !== myAum.identifier))
      .then(() => {
        // Has to setTimeout of 0.3s before updating entity page by fetching new data to get new review date due to asynchronism between server and async worker that handles nextReviews
        setTimeout(() => {
          props.update();
        }, 300);
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  const columns: Array<Column> = [
    { id: 'label', label: t('utils.label'), minWidth: 'min-content' },
    { id: 'type', label: t('utils.type'), minWidth: 'min-content' },
    // Old column used before AAIS-297 with the aum reformating
    // { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'value', label: t('utils.value'), minWidth: 'min-content' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  const rows = props.list.map( (aum: AumContributor) => {
    let className : string | undefined;

    if (!aum.enabled) {
      className = classes.disabledRow;
    }

    return (
      {
        id: aum.identifier,
        className,
        value: columns.map(column => (
          <>
            {column.id === 'label' &&
              <Stack display='flex' flexDirection='row' alignItems='center'>
                <Box mr={1} color={aum.enabled ? undefined : theme.palette.error.dark}>
                  {aum.label}
                </Box>
                {!aum.enabled &&
                  <Tooltip title='No AuM values found ......' arrow>
                    <ErrorOutlineRoundedIcon fontSize='small' color='error'/>
                  </Tooltip>
                }
              </Stack>
            }
            {column.id === 'type' && (aum.recordType || aum.type)}
            {/* Old column used before AAIS-297 with the aum reformating */}
            {/* {column.id === 'gpsl' &&
					<TagIcon val={aum.tags.GPSL}/>
        } */}
            {column.id === 'value' &&
          (props.aumService.type !== 'assetMgr' && props.aumService.type !== 'investMgr' ?
            aum.code && <strong>{aum.code}</strong>
            :
            aum.amount && <strong>{roundValue(aum.amount.amount || 0)} {aum.amount.currency}</strong>
          )
            }
            {column.id === 'actions' && !props.readonly &&
          <div>
            <Tooltip title='Remove' arrow>
              <div>
                <CustomIconButton
                  variant='contained'
                  color='secondary'
                  small
                  onClick={() => handleRemoveAum(aum)}
                >
                  <RemoveRoundedIcon />
                </CustomIconButton>
              </div>
            </Tooltip>
          </div>
            }
          </>
        ))
      });
  });

  return (
    <>
      {props.type !== 'manual' && props.list.length > 0 ?
        <CustomTable columns={columns} rows={rows} /> :
        <Typography>{t('dds.tabs.parameters.aums.noAUMContributor')}</Typography>
      }
      {!props.readonly && <AumDataModal list={props.list} aumService={props.aumService} aumType={props.aumType} update={props.update} />}
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AumsTable;

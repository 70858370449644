import { ActionLog, ParamFilter } from "@deecision/deeligenz-interfaces";

const getUniqueFiltersFromActionLog = (list: ActionLog[]): ParamFilter[] => {
  const uniqueActionType = Array.from(
    new Map(
      list?.map(item => [item.action.type, { label: item.action?.type, value: item.action?.type }])
    ).values()
  );
  const uniqueUserNames = Array.from(
    new Map(
      list?.map(item => [item.actor?.name, { label: item.actor?.name || '', value: item.actor?.name || '' }])
    ).values()
  );
  const filters: ParamFilter[] = [
    {
      id: 'actionType',
      type: 'multiSelect',
      label: 'Actions',
      values: uniqueActionType || undefined
    },
    {
      id: 'userName',
      type: 'multiSelect',
      label: 'Users',
      values: uniqueUserNames || undefined
    }
  ];

  return (filters);
};

export default getUniqueFiltersFromActionLog;
import { ApiResponse, Filter, Nullable, PossibleAction, UploadReturn } from '@deecision/deeligenz-interfaces';
import axios, { AxiosProgressEvent, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { AumImport } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import { API_AUM } from '../api/api';
import { AnyParams, BaseEntityService, BaseUploadService, PaginatedParams } from './data';
import ServiceError from './errors';

class AumImportService extends BaseEntityService<AumImport> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return 'api/aum-imports';
  }

  makeActions(id: string, call: PossibleAction['call']): Promise<ApiResponse<unknown>> {
    return API_AUM.get(`${this.getBaseUrl()}/${id}/actions/activate`, call)
      .then(res => this.handleResponse<unknown>(res));
  }

  getPossibleGroupedActions(): Promise<ApiResponse<Nullable<Array<PossibleAction>>>> {

    return Promise.resolve({
      data: null,
      error: false,
      responseTimestamp: '',
      statusCode: 200
    });
  }

  getPossibleActions(id: string): Promise<ApiResponse<Nullable<PossibleAction[]>>> {
    return Promise.resolve({
      data: null,
      error: false,
      responseTimestamp: `${id}`,
      statusCode: 200
    });
  }

  getListInfo(): {url: string, defaultParams: AnyParams} {
    return {
      url: this.getBaseUrl(),
      defaultParams: {}
    };
  }

  getEntityList(itemsPerPage?: number, skip?: number, filters?: Array<Filter>): Promise<ApiResponse<Nullable<Array<AumImport>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };

    if (itemsPerPage) {
      params.pageSize = itemsPerPage;
    }

    if (skip) {
      const adaptSkipToPage = skip / (itemsPerPage || 20);

      params.page = adaptSkipToPage;
    }

    if (filters) {
      filters.forEach((filter) => {
        // Now add 'f_' to the filters so the server knowns how to interpret them
        params[filter.id] = filter.value;
      });
    }

    return API_AUM.get(this.getBaseUrl(), { params })
      .then(res => this.handleResponse<Array<AumImport>>(res));
  }

  getEntity(id: string): Promise<ApiResponse<Nullable<AumImport>>> {

    return API_AUM.get(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<AumImport>(res));
  }

  activate(id: string): Promise<ApiResponse<Nullable<void>>> {
    return API_AUM.get(`${this.getBaseUrl()}/${id}/actions/activate`)
      .then(res => this.handleResponse<void>(res));
  }
}

// This is connected directly to aum-connector and not to deeligenz-server
export class AumUploadService extends BaseUploadService {
  username: string = '';
  
  constructor(keycloakId: string) {
    super();
    this.username = keycloakId;
  }

  getBaseUrl() : string {
    return 'api/aum-imports';
  }

  getUploadUrl(): string {
    return `${this.getBaseUrl()}`;
  }

  // Overwrited BaseUploadService.uploadFile because we do not call the same api
  // Other solution is to send a 'API' param to use directly but has to change everywhere or an optional 'AUM' boolean param to call API.post or API_AUM.posts
  uploadFile(file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void, setCancelSource: ((source: CancelTokenSource) => void)): Promise<ApiResponse<Nullable<UploadReturn>>> {
    const axiosCancelTokenSource = axios.CancelToken.source();
    const formData = new FormData();

    setCancelSource(axiosCancelTokenSource);

    if (file.type.length === 0) {
      return new Promise<ApiResponse<Nullable<null>>>((resolve, reject) => {
        reject(new ServiceError(500, 'File has no extension'));
      });
    }

    const config: AxiosRequestConfig = {
      onUploadProgress,
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    formData.append('file', file);
    formData.append('username', this.username || 'unknown');

    return API_AUM.post(`${this.getUploadUrl()}`, formData, config)
      .then(res => this.handleResponse<UploadReturn>(res));
  }
}

export default AumImportService;
// import React from 'react'
import axios from 'axios';
import { API_AUM_CONNECTOR_BASE_URL, API_BASE_URL } from '../utils/env';

/* export type AxiosResponseAPI<T> = Nullable<{
  error: Nullable<Error>,
  response: Nullable<T>
}>; */

export const API = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const API_AUM = axios.create({
  baseURL: API_AUM_CONNECTOR_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

/* function Api<T>(props: AxiosRequestConfig): AxiosResponseAPI<T | null> {
  const baseUrl = props.baseURL ? '' : API_BASE_URL;
  const [response, setResponse] = React.useState<T | null>(null);
  const [error, setError] = React.useState<Error | null>(null);
  const [url, setUrl] = React.useState<string | undefined | null>(null);
  const headers = {
      'Content-Type': 'multipart/form-data',
    }
  React.useEffect(() => {
    if (props.url?.includes('undefined') || props.method === 'post' && (props.data === undefined || props.data.entries() === undefined || Array.from(props.data?.entries()).length < 1))
      return;
    props.headers = props.method === 'post' ? headers : undefined;
    const fetchData = async (): Promise<void> => {
      try {
        setUrl(props.url);
        const res = await axios({ ...props, url: `${baseUrl}${props.url}` });
        setResponse(res.data);
      } catch (error) {
        setError(error);
      }
    };
    fetchData().then(() => {
      return;
    });
  }, [props.url, props.data]);
  if (url !== props.url && response !== null) {
    setResponse(null);
  }
  return { response, error };
} */

export default API;

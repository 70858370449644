import { ApiResponse, AumContributor, Filter, Nullable, PossibleAction } from '@deecision/deeligenz-interfaces';
import { API } from '../api/api';
import { AnyParams, BaseEntityService, PaginatedParams } from './data';

class AumContributorsService extends BaseEntityService<AumContributor> {
  entityType: string;

  constructor(entityType: string) {
    super();
    this.entityType = entityType;
  }
  
  getBaseUrl() : string {
    return 'api/v1';
  }
  
  makeActions(id: string, call: PossibleAction['call']): Promise<ApiResponse<unknown>> {
    return API.get(`${this.getBaseUrl()}/${id}/actions/activate`, call)
      .then(res => this.handleResponse<unknown>(res));
  }
  
  getPossibleGroupedActions(): Promise<ApiResponse<Nullable<Array<PossibleAction>>>> {
  
    return Promise.resolve({
      data: null,
      error: false,
      responseTimestamp: '',
      statusCode: 200
    });
  }
  
  getPossibleActions(id: string): Promise<ApiResponse<Nullable<PossibleAction[]>>> {
    return Promise.resolve({
      data: null,
      error: false,
      responseTimestamp: `${id}`,
      statusCode: 200
    });
  }

  getListInfo(): {url: string, defaultParams: AnyParams} {
    return {
      url: this.getBaseUrl(),
      defaultParams: {}
    };
  }

  getSearchList(query: string): Promise<ApiResponse<Nullable<Array<string>>>> {
    const info = this.getListInfo();

    return API.get(`${info.url}/aum/search`, { params: { name: query, entityType: this.entityType } })
      .then(res => this.handleResponse<Array<string>>(res));
  }
  
  getEntityList(itemsPerPage?: number, skip?: number, filters?: Array<Filter>): Promise<ApiResponse<Nullable<Array<AumContributor>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };
  
    if (itemsPerPage) {
      params.pageSize = itemsPerPage;
    }
  
    if (skip) {
      const adaptSkipToPage = skip / (itemsPerPage || 20);

      params.page = adaptSkipToPage;
    }
  
    if (filters) {
      filters.forEach((filter) => {
        // Now add 'f_' to the filters so the server knowns how to interpret them
        params[filter.id] = filter.value;
        if (filter.id === 'f_name') {
          params.page = 0;
        }
      });
    }
  
    return API.get(`${this.getBaseUrl()}/aum/contributors?entityType=${this.entityType}`, { params })
      .then(res => this.handleResponse<Array<AumContributor>>(res));
  }
  
  getEntity(id: string): Promise<ApiResponse<Nullable<AumContributor>>> {
  
    return API.get(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<AumContributor>(res));
  }

}

export default AumContributorsService;
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionLog, ParamFilter } from '@deecision/deeligenz-interfaces';
import AuditTrailTable from './audittrailtable';
import { ListRendererMap, MultiModeList } from '../entity/list';
import { getSearchParamsContext, SearchParamsContextProvider, SearchParamsAccess } from '../params';
import AuditTrailService from '../../services/AuditTrailService';
import AuditTrailDataProvider from './audittrailprovider';
import AuditTrailFilters, { ParamFilterContext } from './components/audittrailfilters';
import getUniqueFiltersFromActionLog from './utils/getFiltersFromActionLog';
import DdAuditTrailService from '../../services/DdAuditTrailService';

interface AuditTrailProps {
  entityType: string,
  id: string
}

export function AuditTrailMultiModeList(props: AuditTrailProps): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const auditTrailService = props.entityType === 'dds' ? new DdAuditTrailService(props.id) : new AuditTrailService(props.entityType, props.id);
  const listDataProvider = new AuditTrailDataProvider(props.entityType, searchParamsAccess, props.id);
  const [auditTrailFilters, setAuditTrailFilters] = useState<ParamFilter[]>([]);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No events...</div>
    </>
  );

  const renderLoading = () => (
    <div style={{ marginTop: '40px' }}>Loading...</div>
  );

  const renderAsTable = (eltList: ActionLog[]): ReactElement => (
    <>
      <AuditTrailTable data={eltList} paramsSort={listDataProvider.sortParamsManager}/>
      {/* <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} /> */}
    </>
  );

  const renderers: ListRendererMap<ActionLog> = {
    'table': esList => renderAsTable(esList)
  };

  const getAndSetUniqueFiltersFromData = () => {
    const hasId = /\/[^/]+$/.test(listDataProvider.entityService.getBaseUrl());

    // We need to test if we have retrieve the id before fetching filters dynamically due to asynchronism
    if (hasId) {
      listDataProvider.entityService.getEntityList().then((res) => {
        if (res.data) {
          const filters = getUniqueFiltersFromActionLog(res.data);

          setAuditTrailFilters(filters);
        }
      });
    }
  };

  useEffect(() => {
    getAndSetUniqueFiltersFromData();
  }, [listDataProvider.entityService.getBaseUrl()]);

  return (
    <ParamFilterContext.Provider value={auditTrailFilters} >
      <MultiModeList
        listDataProvider={listDataProvider}
        modes={['table']}
        defaultMode='table'
        renderers={renderers}
        renderEmpty={renderEmpty}
        renderLoading={renderLoading}
        filtersComponent={AuditTrailFilters}
        actionsService={auditTrailService}
      />
    </ParamFilterContext.Provider>
  );
}

function AuditTrail(props: AuditTrailProps): ReactElement {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(params);
  const searchParamsAccess = new SearchParamsAccess(searchParams, setSearchParams, navigate);

  const setDefaultFilters = () => {
    if (searchParamsAccess.getParam('f_scope') === null && props.entityType !== 'dds') {
      searchParamsAccess.updateParam('f_scope', 'audited_entity');
      setSearchParams(params);
    }
  };

  useEffect(() => {
    setDefaultFilters();
  }, []);

  return (
    <SearchParamsContextProvider value={searchParamsAccess}>
      <AuditTrailMultiModeList {...props} />
    </SearchParamsContextProvider>
  );
}

export default AuditTrail;
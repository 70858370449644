import React, { ReactElement, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Paper, Tabs, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Tab from '@mui/material/Tab';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTabsStyles } from '../layout/tabs/dynamictabs';
import { MeetingsTabsInfo } from '../../types/types';
import { mainRadius } from '../../themes/theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    marginTop: '-8px',
    padding: '4px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
    borderRadius: mainRadius
  },
  title: {
    marginLeft: '12px'
  },
  tabsIndicator: {
    height: '32px',
    zIndex: 2,
    backgroundColor: 'white', // || 'rgba(0, 0, 0, 0.8)', // commented after upgrading to deel-interfaces 1.1.14 because error always truthy
    borderRadius: mainRadius
  },
  selectedTab: {
    color: `${theme.palette.primary.main  } !important`,
    transition: 'all 0.2s ease-in-out'
  }
}));

function MeetingsTabs(props: { tabsInfo: MeetingsTabsInfo, readonly?: boolean}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const classesTabs = useTabsStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let tabId = pathname.split('/')[1];
  let tabNb = props.tabsInfo.findIndex(t => t.path === tabId);
  const [value, setValue] = React.useState(tabNb > 0 ? tabNb : 0);

  useEffect(() => {
    tabId = pathname.split('/').pop() || '';
    tabNb = props.tabsInfo.findIndex(t => t.path === tabId);
    if (props.readonly) {
      if (tabId === 'allocation') {
        setValue(0);
      } else {
        setValue(tabNb > 0 ? tabNb : 0);
      }
    } else {
      setValue(tabNb > 0 ? tabNb : 0);
    }
  }, [props.readonly, pathname]);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
    navigate(`${props.tabsInfo[newValue].path}`);
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        className={classesTabs.tabs}
        variant='scrollable'
        classes={{ scroller: classesTabs.scroller }}
        TabScrollButtonProps={{ className: classesTabs.scrollButtons }}
        TabIndicatorProps={{
          className: classes.tabsIndicator
        }}
      >
        { props.tabsInfo.map(tabInfo => (
          <Tab
            key={tabInfo.path}
            label={tabInfo.label}
            className={classesTabs.tab}
            classes={{ selected: classes.selectedTab }}
            disableRipple
            disabled={props.readonly && tabInfo.path === 'allocation'}
          />
        ))}
      </Tabs>
    </Paper>
  );
}

export default MeetingsTabs;

import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Snackbar } from '@mui/material';
import { ApiFilter, AumContributor, ParamFilter } from '../../../types/types';
import { FilterParamsManager, ListRendererMap, MultiModeList } from '../../entity/list';
import CustomPagination from '../../layout/pagination/pagination';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../params';
import AumContributorsService from '../../../services/aumcontributors';
import GenericFilters from '../../filters/filters';
import { AumSelectionProps } from './type';
import AumSelectionTable from './aumselectiontable';
import AumSelectionFooter from './aumselectionfooter';
import AumDataProvider from './aumdataprovider';
import ServiceError from '../../../services/errors';

export function AumContributorsSelectionFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[], entityType?: string}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    [];

  filters.push({ id: 'name', type: 'search', label: 'Name', autoComplete: query => new AumContributorsService(props.entityType || '').getSearchList(query) });

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export function AumSelectionMultiModeList(props: Omit<AumSelectionProps, 'data'>): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const aumSelectionService =new AumContributorsService(props.aumService.type);
  const listDataProvider = new AumDataProvider(props.aumService.type, searchParamsAccess);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [refListOfAllAumContributors, setRefListOfAllAumContributors] = useState<AumContributor[]>([]);
  const [selectedIds, setSelectedIds] = useState<{identifier: string, label: string}[]>(
    props.selectedList.map(contributor => ({ identifier: contributor.identifier, label: contributor.label, enabled: contributor.enabled }))
  );
  const processingErrorsSelectedIds = props.selectedList.filter(item => item.enabled === false);

  useEffect(() => {
    aumSelectionService.getEntityList(100000)
      .then(res => (res.data ? setRefListOfAllAumContributors(res.data) : undefined))
      .catch((err) => {
        setErrorMsg(`There may be no AuM imported or aum-connector service is down. ${ServiceError.getErrorMsg(err)}`);
        setSnackbarOpen(true);
      });
  }, []);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No imports...</div>
    </>
  );

  const renderLoading = () => (
    <div style={{ marginTop: '40px' }}>Loading...</div>
  );

  const renderAsTable = (eltList: AumContributor[], totalCount: number): ReactElement => (
    <>
      {/* We are using selectedIds at this level to be able to put the Table and the footer with the customPagination in between but it would be nice to architecture it in a different way */}
      {/* The check logic with selectedIds state updating is done inside the AumSelectionTable and then sent to AumSelectionFooter to display all the selectedIds withitn the eltList */}
      <Box height='55vh'>
        <AumSelectionTable {...props} data={eltList} selectedIds={selectedIds} setSelectedIds={setSelectedIds}/>
      </Box>
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      {/* Overrinding Data here to get the list of all aumContributors instead of the limited pagination of 20 */}
      <AumSelectionFooter {...props} data={refListOfAllAumContributors} selectedIds={selectedIds} processingErrorsSelectedIds={processingErrorsSelectedIds}/>
    </>
  );

  const renderers: ListRendererMap<AumContributor> = {
    'table': (esList, totalCount) => renderAsTable(esList, totalCount)
  };

  return (
    <>
      <MultiModeList
        listDataProvider={listDataProvider}
        modes={['table']}
        defaultMode='table'
        renderers={renderers}
        renderEmpty={renderEmpty}
        renderLoading={renderLoading}
        filtersComponent={AumContributorsSelectionFilters}
        actionsService={new AumContributorsService(props.aumService.type)}
        entityType={props.aumService.type}
      />
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

function AumContributorsSelection(props: Omit<AumSelectionProps, 'data'>): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <AumSelectionMultiModeList {...props} />
    </SearchParamsContextProvider>
  );
}

export default AumContributorsSelection;
import React, { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { darken, Theme } from '@mui/material';
import { AumImport, AumImportErrorLevel } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../params';
import {
  FilterParamsManager,
  ListRendererMap,
  MultiModeList
} from '../../entity/list';
import { ApiFilter, ParamFilter } from '../../../types/types';
import GenericFilters from '../../filters/filters';
import CustomPagination from '../../layout/pagination/pagination';
import CustomTable, { Column } from '../../table/table';
import { makeColoredRow } from '../import/importlist';
import AumImportService from '../../../services/aumimport';
import DdAumDataProvider from './ddaumdataproviter';

interface DdAumTableProps {
  list: AumImport[],
  paramsSort?: FilterParamsManager
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  failedRow: makeColoredRow(theme.palette.error.light),
  warningRow: makeColoredRow(theme.palette.warning.light),
  infoRow: makeColoredRow(theme.palette.info.light),
  completedRow: makeColoredRow(theme.palette.success.light),
  baseRow: makeColoredRow(darken(theme.palette.success.dark, 0.1))
}));

// !! If there is any change to do here on the style or architecture of the table, maybe check in the UploadTable that uses a different component of this but is related to (since the aum import is based on the uploading)
export function DdAumTable(props: DdAumTableProps) : ReactElement {
  const classes = useStyles({});
  let columns: Array<Column> = [];

  columns = [
    ...columns,
    { id: 'originalFileName', label: 'Original File Name', minWidth: 'min-content' },
    { id: 'active', label: 'Active', minWidth: 'min-content', align: 'center' },
    { id: 'status', label: 'Status', minWidth: 'min-content', align: 'center' },
    { id: 'date', label: 'Date', minWidth: 'min-content', align: 'center' },
    { id: 'startTime', label: 'Full Start Time', minWidth: 'min-content', align: 'center' },
    { id: 'nbAssetManager', label: 'Nb AssetManager', minWidth: 'min-content', align: 'center' },
    { id: 'nbFunds', label: 'Nb Funds', minWidth: 'min-content', align: 'center' }
  ];

  const rows = props.list.map((importObj: AumImport) => {
    let className : string | undefined = classes.baseRow;
    if (importObj.active) {
      className = classes.completedRow;
    }
    if (importObj.parsingErrors?.some(error => error.level === AumImportErrorLevel.INFO)) {
      className = classes.infoRow;
    }
    if (importObj.parsingErrors?.some(error => error.level === AumImportErrorLevel.WARNING)) {
      className = classes.warningRow;
    }
    if (importObj.parsingErrors?.some(error => error.level === AumImportErrorLevel.ERROR)) {
      className = classes.failedRow;
    }

    return ({
      id: importObj._id,
      className,
      value: columns.map(column => (
        <>
          {column.id === 'originalFileName' && <Link className={classes.link} to={`/admin/aum/${importObj._id}`}>{importObj.originalFileName}</Link>}
          {column.id === 'active' && (importObj.active ? <strong>Yes</strong> : '-')}
          {column.id === 'status' && importObj.status}
          {column.id === 'date' && importObj.startTime.split('T')[0]}
          {column.id === 'startTime' && importObj.startTime}
          {column.id === 'nbAssetManager' && importObj.stats?.nbAssetMgrs}
          {column.id === 'nbFunds' && importObj.stats?.nbFunds}
        </>
      ))
    });
  });

  return (
    <>
      <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} />
    </>
  );
}

export function DdAumFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    [];

  filters.push({ id: 'active', type: 'boolean', label: 'Active' });
  // filters.push({ id: 'simpleId', type: 'search', label: 'Simple Id', autoComplete: query => new AumImportService().getSearchList(query) });

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export function DdAumMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new DdAumDataProvider(searchParamsAccess);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No imports...</div>
    </>
  );

  const renderLoading = () => (
    <div style={{ marginTop: '40px' }}>Loading...</div>
  );

  const renderAsTable = (eltList: AumImport[], totalCount: number): ReactElement => (
    <>
      <DdAumTable list={eltList} />
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderers: ListRendererMap<AumImport> = {
    'table': (esList, totalCount) => renderAsTable(esList, totalCount)
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table']}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={DdAumFilters}
      actionsService={new AumImportService()}
    />
  );
}

function DdAum(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <DdAumMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default DdAum;

import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../components/params';
import { DdTable } from './ddtable';
import { EntityListDataProvider, ListRendererMap, MultiModeList } from '../../../components/entity/list';
import { DataDueDiligence } from '../../../types/types';
import { DdService } from '../../../services/dds';
import DdFilters from './filters';
import CustomPagination from '../../../components/layout/pagination/pagination';
import TableSkeleton from '../../../components/layout/loading/tableskeleton';
import DdsCards from './ddcards';
import CardsSkeleton from '../../../components/layout/loading/cardsskeleton';

class DdListDataProvider extends EntityListDataProvider<DataDueDiligence> {
  constructor(searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number, itemsPerPageMax?: number) {
    super(searchParamsAccess, new DdService(), itemsPerPageMin, itemsPerPageMax);
  }
}

export function DdMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const { t } = useTranslation();
  const listDataProvider = new DdListDataProvider(searchParamsAccess);
  const listDataProviderForCards = new DdListDataProvider(searchParamsAccess, 8, 40);
  const [displayMode, setDisplayMode] = useState<string>('cards');
  let singleReviewsColumns = true;

  singleReviewsColumns = searchParamsAccess.getParam('f_singleColsReviews') === 'true';

  if (searchParamsAccess.getParam('f_singleColsReviews') === null && displayMode === 'table') {
    searchParamsAccess.updateParam('f_singleColsReviews', 'true');
    singleReviewsColumns = true;
  }

  const notifyParentDisplayChange = (newMode: string) => {
    setDisplayMode(newMode);
  };

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>{t('dds.cardsAndTable.noDds')}</div>
    </>
  );

  const renderLoading = (mode?: string) => (
    mode === 'cards'?
      <CardsSkeleton nbCards={8} /> :
      <TableSkeleton nbColumns={8} />
  );

  const renderAsTable = (eltList: Array<DataDueDiligence>, totalCount: number) => (
    <>
      <DdTable list={eltList} paramsSort={listDataProvider.sortParamsManager} singleReviewCol={singleReviewsColumns} />
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderAsCards = (eltList: Array<DataDueDiligence>, totalCount: number) =>   // eslint-disable-line @typescript-eslint/no-unused-vars
    (
      <>
        <DdsCards ddList={eltList} />
        <CustomPagination paramsPager={listDataProviderForCards.paramsPager} totalItems={totalCount} />
      </>
    );

  const renderers: ListRendererMap<DataDueDiligence> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table', 'cards']}
      defaultMode='cards'
      renderers={renderers}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      filtersComponent={DdFilters}
      notifyParentDisplayChange={notifyParentDisplayChange}
    />
  );
}

function DdListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <DdMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default DdListingPage;

import { AumImport } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import { API } from '../api/api';
import { DdElementGroupType, Import, ImportGroup, ImportGroupWithStats } from '../types/importtypes';
import { ApiResponse, DdReportBlock, DdTemplate, Nullable } from '../types/types';
import { AnyParams, BaseEntityService, PaginatedParams } from './data';

export class ImportGroupService extends BaseEntityService<ImportGroup> {
  constructor() {
    super();
  }

  getAdminUrl(): string {
    return '/api/v1/admin';
  }

  getBaseUrl() : string {
    return `${this.getAdminUrl()}/importGroups`;
  }

  getAllWithStats(): Promise<ApiResponse<Nullable<Array<ImportGroupWithStats>>>> {
    return API.get(`${this.getBaseUrl()}/allWithStats`)
      .then(res => this.handleResponse<Array<ImportGroupWithStats>>(res));
  }

  getImportGroupImports(importGroupId: string): Promise<ApiResponse<Nullable<Array<Import>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };

    params.limit = 1000;

    return API.get(`${this.getBaseUrl()}/${importGroupId}/imports`, { params })
      .then(res => this.handleResponse<Array<Import>>(res));
  }
}

export class DdImportService extends ImportGroupService {
  getUploadUrl(): string {
    return `${this.getBaseUrl()}/uploadDD`;
  }
}

export class DGPSLImportService extends ImportGroupService {
  getUploadUrl(): string {
    return `${this.getAdminUrl()}/gpsl`;
  }
}

export class TemplateImportService extends ImportGroupService {
  getUploadUrl(): string {
    return `${this.getBaseUrl()}/uploadTemplate`;
  }
}

export class ImportService extends BaseEntityService<Import> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/admin/imports';
  }

  getListInfo(): {url: string, defaultParams: AnyParams} {
    return {
      url: this.getBaseUrl(),
      defaultParams: {
        sortBy: 'ddImportInfo.ddDirName'
      }
    };
  }
}

export class DdElementGroupsService extends BaseEntityService<DdElementGroupType> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/admin/ddelementgroups';
  }
}

export class DdTemplatesService extends BaseEntityService<DdTemplate> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/ddtemplates';
  }
}

// // This is connected directly to aum-connector and not to deeligenz-server
export class DdAumService extends BaseEntityService<AumImport> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/aum-imports';
  }
}

export class DdReportBlocksService extends BaseEntityService<DdReportBlock> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/admin/reportBlockDesc';
  }
}

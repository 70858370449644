import React, { ReactElement } from "react";
import Grid from "@mui/system/Unstable_Grid";
import { Chip, Stack } from "@mui/material";
import { ActionLog } from "@deecision/deeligenz-interfaces";
import CustomTable, { Column } from "../table/table";
import { ActionTypeColor } from "./audittrailenumtype";
// import AuditTrailDynamicIcon from "./components/auditactionicon";
import { FilterParamsManager } from "../entity/list";

interface AuditTrailTableProps {
    data: ActionLog[],
    paramsSort?: FilterParamsManager
}

function AuditTrailTable(props: AuditTrailTableProps): ReactElement {
  const columns: Array<Column> = [
    { id: 'action.type', label: 'Action', minWidth: 'min-content', align: 'center' },
    // { id: 'entity.type', label: 'Target', minWidth: 'min-content', align: 'center' },
    { id: 'action.description', label: 'Description', minWidth: 'min-content', align: 'center' },
    { id: 'actor.name', label: 'User', minWidth: 'min-content', align: 'center' },
    { id: 'timestamp', label: 'Timestamp', minWidth: 'min-content', align: 'center' }
  ];
  const rows = props.data?.map((event: ActionLog) => ({
    id: event.id,
    value: columns.map(column => (
      <>
        {column.id === 'action.type' &&
          <Stack justifyContent='center' alignItems='center' direction='row' spacing={1}>
            {/* TODO: FIX THIS because the type has changed */}
            {/* {event.action.type &&
              <AuditTrailDynamicIcon
                iconType={event.action.type}
                color={colorToPalette[ActionTypeColor[event.action.type]] as ColorPalette || 'inherit'}
              />
            } */}
            <span style={{ color: ActionTypeColor[event.action.type] }}>{event.action.type}</span>
          </Stack>
        }
        {/* {column.id === 'entity.type' &&
          <Box>
            {event.entity?.type || event.scope || 'N/A'}
            {event.entity && event.entity?.id &&
              <Tooltip arrow title={`/dds/${event.entity?.id}`}>
                <OpenInNewRoundedIcon
                  fontSize='small'
                  onClick={() => {
                    navigate({ pathname: `/dds/${event.entity.id}` });
                  }}
                  style={{ cursor: 'pointer', width: '15px', height: '15px'  }}
                />
              </Tooltip>
            }
          </Box>
        } */}
        {column.id === 'action.description' && event.action.description }
        {column.id === 'timestamp' &&
          <Chip
            label={(new Date(event.timestamp)).toUTCString()}
            color='default'
            size='small'
          />
        }
        {column.id === 'actor.name' && event.actor.name}
      </>
    ))
  }));

  return (
    <Grid container xs={12} p={1} pb={4} spacing={2}>
      <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} nonScrollableY/>
    </Grid>
  );
}

export default AuditTrailTable;
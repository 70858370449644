import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AumImportErrorLevel, AumImportProcessingError } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import { ddAumContext } from '../../../hooks/datatabs';
import { makeColoredRow } from '../import/importlist';
import CustomTable, { Column, Row } from '../../table/table';
import { AssetMgrService } from '../../../services/assetmgr';
import { FundService } from '../../../services/fund';
import { StrategyService } from '../../../services/strategy';
import { MandateService } from '../../../services/mandate';
import { InvestMgrService } from '../../../services/investmgr';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  failedRow: makeColoredRow(theme.palette.error.light),
  warningRow: makeColoredRow(theme.palette.warning.light),
  infoRow: makeColoredRow(theme.palette.info.light),
  completedRow: makeColoredRow(theme.palette.success.light),
  successRow: makeColoredRow(theme.palette.success.light)
}));

type EntityType = 'assetMgr' | 'fund' | 'strategy' | 'mandate' | 'investMgr';

function DdProcessingErrors(): ReactElement {
  const ddAum = ddAumContext();
  const classes = useStyles({});
  const [entityNames, setEntityNames] = useState<string[]>([]);

  const service = {
    'assetMgr': new AssetMgrService(),
    'fund': new FundService(),
    'strategy': new StrategyService(),
    'mandate': new MandateService(),
    'investMgr': new InvestMgrService()
  };

  function isEntityType(type: string): type is EntityType {
    return ['assetMgr', 'fund', 'strategy', 'mandate', 'investMgr'].includes(type);
  }

  const columns: Array<Column> = [
    { id: 'level', label: 'Level', minWidth: 'min-content' },
    { id: 'code', label: 'Code', minWidth: 'min-content' },
    { id: 'message', label: 'Message', minWidth: 'min-content' },
    { id: 'auditedEntity', label: 'Audited Entity', minWidth: 'min-content' },
    { id: 'contributor', label: 'Contributor', minWidth: 'min-content' }
  ];
  
  const provideRowsValue = () => {
    const result = ddAum?.processingErrors.map((event: AumImportProcessingError, index: number) => {
      let className : string | undefined;
      let entityTypeForBaseUrl: string = '';

      if (event.level === AumImportErrorLevel.ERROR) {
        className = classes.failedRow;
      } else if (event.level === AumImportErrorLevel.WARNING) {
        className = classes.warningRow;
      } else if (event.level === AumImportErrorLevel.INFO) {
        className = classes.infoRow;
      }

      if (isEntityType(event.context.auditedEntity.type)) {
        const urlTemplate = service[event.context.auditedEntity.type].getBaseUrl();
        const parts = urlTemplate.split('/');
        entityTypeForBaseUrl = parts[parts.length - 1];
      };

      return ({
        id: `${event.level}-${event.code}`,
        className,
        value: columns.map(column => (
          <div key={column.id}>
            {column.id === 'level' && event.level}
            {column.id === 'code' && event.code}
            {column.id === 'message' && event.message}
            {column.id === 'auditedEntity' &&
                <Link to={`/${entityTypeForBaseUrl}/${event.context.auditedEntity.id}/parameters`}>
                  {entityNames[index] || event.context.auditedEntity.id}
                </Link>
            }
            {column.id === 'contributor' && event.context.contributor &&
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {event.context.contributor.label}
                  </Grid>
                </Grid>
            }
          </div>
        ))
      });
    }) || [];

    return result;
  };

  const rows: Row[] = useMemo(() => provideRowsValue(), [entityNames]);

  useEffect(() => {
    const entityNamePromises = ddAum?.processingErrors.map(async (event: AumImportProcessingError) => {
      if (isEntityType(event.context.auditedEntity.type)) {
        try {
          const res = await service[event.context.auditedEntity.type].getEntity(event.context.auditedEntity.id);

          return res.data?.name || event.context.auditedEntity.id;
        } catch {
          return event.context.auditedEntity.id;
        }
      }
      
      return event.context.auditedEntity.id;
    }) || [];

    Promise.all(entityNamePromises).then(names => setEntityNames(names));
  }, [ddAum?.processingErrors]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h3>Errors / Warnings</h3>
            { (ddAum?.processingErrors?.length || 0 ) > 0 &&
              <CustomTable columns={columns} rows={rows} size='small' />
            }
            { ddAum?.processingErrors?.length === 0 && <>No processing errors or warnings...</>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DdProcessingErrors;

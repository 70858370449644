import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';

interface HeaderPropertyValueProps {
  link?: boolean,
  children: Array<ReactElement> | ReactElement | string | string[] | undefined | null,
  color?: string,
  after?: string,
  childrenNotWrapped?: boolean
}

function HeaderPropertyValue(props: HeaderPropertyValueProps): ReactElement {
  return (
    <Grid item xs display='flex' alignItems='baseline'>
      <Typography variant='body1' style={{ marginTop: props.link ? '-6px' : '-1px', color: props.color, fontWeight: 500 }}>
        {props.children}
      </Typography>
      {props.after &&
        <Typography variant='body2' marginLeft='6px' fontWeight={500}>
          {props.after}
        </Typography>
      }
    </Grid>
  );
}

export default HeaderPropertyValue;

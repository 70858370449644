import { API } from '../api/api';
import {
  Amount,
  ApiResponse, AumContributor, BaseAumContributor,
  Nullable
} from '../types/types';
import { BaseService } from './data';

class AuditedEntitiesService extends BaseService {
  basePath: string;

  type: string;

  _id: string;

  constructor(type: string, id: string) {
    super();
    this.basePath = '';
    this.type = type;
    this._id = id;
  }

  getBaseUrl(): string {
    this.basePath = `/api/v1/auditedentities/${this.type}/${this._id}`;

    return this.basePath;
  }

  getAumUrl(): string {
    return `${this.getBaseUrl()}/aum`;
  }

  getAllAUM(): Promise<ApiResponse<Nullable<AumContributor[]>>> {
    return API.get(`/api/v1/aum`)
      .then(res => this.handleResponse<AumContributor[]>(res));
  }

  getContributorsByType() {
    return API.get(`/api/v1/aum/contributors?entityType=${this.type}`);
  }

  getAUM(id: string): Promise<ApiResponse<Nullable<AumContributor>>> {
    return API.get(`${this.getAumUrl()}/${id}`)
      .then(res => this.handleResponse<AumContributor>(res));
  }

  setAUM(aums: (AumContributor | BaseAumContributor)[]): Promise<ApiResponse<Nullable<AumContributor[]>>> {
    return API.put(`${this.getAumUrl()}/contributors`, aums)
      .then(res => this.handleResponse<AumContributor[]>(res));
  }

  setManual(manualAum: Amount): Promise<ApiResponse<Nullable<Amount>>> {
    return API.post(`${this.getAumUrl()}/manual`, { manualAum })
      .then(res => this.handleResponse<Amount>(res));
  }

  recalculateNextReviewDate(): Promise<ApiResponse<Nullable<string>>> {
    return API.get(`${this.getBaseUrl()}/recalculateNextReviewDate`)
      .then(res => this.handleResponse<string>(res));
  }
}

export default AuditedEntitiesService;

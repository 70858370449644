import React, { ReactElement } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { NextReview, Ratings } from '../../types/types';
import HeaderProperty from '../modularcomponents/header/headerproperty';
import HeaderPropertyName from '../modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../modularcomponents/header/headerpropertyvalue';
import { Rating2, Rating1 } from '../../containers/duediligences/listing/datadisplay';
import NextReviews from '../nextreviews/nextReviews';

function HeaderRating(props: { rating?: Ratings, nextReviews?: NextReview[], entityType?: string, reviewsStopped?: boolean }): ReactElement {

  return (
    <>
      <HeaderProperty>
        <Grid item xs={12} style={{ paddingTop: 0, marginBottom: '12px' }}>
          <Stack spacing={2} direction='row' width='128px' height='38px' alignItems='center' justifyContent='space-between'>
            <Typography variant='body2'>
              Global Rating
            </Typography>
            <Rating1 ratings={props.rating} />
          </Stack>
        </Grid>
      </HeaderProperty>
      <HeaderProperty>
        <Grid item xs={12} style={{ paddingTop: 0, marginBottom: '12px' }}>
          <Stack spacing={2} direction='row' width='128px' height='38px' alignItems='center' justifyContent='space-between'>
            <Typography variant='body2'>
              ESG Rating
            </Typography>
            <Rating2 ratings={props.rating} />
          </Stack>
        </Grid>
      </HeaderProperty>
      <HeaderProperty>
        <HeaderPropertyName name='Next Reviews' />
        <HeaderPropertyValue after={props.entityType?.includes('odd') ? 'temporary' : undefined}>
          <NextReviews nextReviews={props.nextReviews} alignLeft showRelatedDd reviewsStopped={props.reviewsStopped} />
        </HeaderPropertyValue>
      </HeaderProperty>
    </>
  );
}

export default HeaderRating;

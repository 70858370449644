import { AumImport } from "@deecision/deeligenz-interfaces/dist/types/importtypes";
import AumImportService from "../../../services/aumimport";
import { EntityListDataProvider } from "../../entity/list";
import { SearchParamsAccess } from "../../params";

class DdAumDataProvider extends EntityListDataProvider<AumImport> {
  constructor(searchParamsAccess: SearchParamsAccess, itemsPerPageMin?: number) {
    super(searchParamsAccess, new AumImportService(), itemsPerPageMin);
  }
}

export default DdAumDataProvider;
import React, { ReactElement, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {  Grid, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Fund } from '../../../types/types';
import CustomTable, { Column } from '../../../components/table/table';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import { Rating2, Rating1 } from '../../duediligences/listing/datadisplay';
import { FilterParamsManager } from '../../../components/entity/list';
import checkRoles from '../../../components/authentification/checkRoles';
import SecureDisplay from '../../../components/authentification/securedisplay';
import { CustomIconButton } from '../../../components/button/button';
import { StyledMenu, useMenuStyle } from '../../../components/layout/navbar/navbar';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import { ReportHelper } from '../../../components/reports/pdfreports';
import TagIcon from '../../../components/aum/tagicon';
import NextReviews from '../../../components/nextreviews/nextReviews';
import allReviewType, { multipleReviewsColumns } from '../../../components/nextreviews/nextreviewstype';

// const useStyles = makeStyles({
//   link: {
//     textDecoration: 'none',
//     color: 'inherit',
//     '&:hover': {
//       textDecoration: 'underline',
//     }
//   }
// });

const useStyles = makeStyles({
  rating: {
    display: 'inline-block'
  }
});

export function FundTable(props: {list: Array<Fund>, paramsSort?: FilterParamsManager, singleReviewCol?: boolean}) : ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const menuClasses = useMenuStyle(theme);
  const { keycloak } = useKeycloak();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [dlOpen, setDlOpen] = useState<null | string>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setDlOpen(id);
  };

  const handleClose = () => {
    setDlOpen(null);
    setAnchorEl(null);
  };

  const columns: Array<Column> = [
    { id: 'fundName', label: 'Fund Name', minWidth: 'min-content' },
    { id: 'assetMgrName', label: 'Asset Manager Name', minWidth: 'min-content' },
    // { id: 'assetClass', label: 'Asset Class', minWidth: 'min-content' },
    // { id: 'geography', label: 'Geography', minWidth: 'min-content' },
    // { id: 'subAssetClass', label: 'SubAsset Class', minWidth: 'min-content' },
    { id: 'rating1', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    // { id: 'nextReviews', label: 'Next Reviews', minWidth: 'min-content', align: 'center' },
    ...(props.singleReviewCol ?
      [{ id: 'nextReviews', label: 'Next Reviews', minWidth: 'min-content', align: 'center' as const }] :
      multipleReviewsColumns
    ),
    { id: 'latestDdType', label: 'Latest Dd type', minWidth: 'min-content', align: 'center' }
  ];

  const columnsOnlyFundCenter: Array<Column> = [
    { id: 'fundName', label: 'Fund Name', minWidth: 'min-content' },
    // { id: 'assetClass', label: 'Asset Class', minWidth: 'min-content' },
    // { id: 'geography', label: 'Geography', minWidth: 'min-content' },
    // { id: 'subAssetClass', label: 'SubAsset Class', minWidth: 'min-content' },
    { id: 'rating1', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    { id: 'nextReviews', label: 'Next Reviews', minWidth: 'min-content', align: 'center' },
    { id: 'latestDdType', label: 'Latest Dd type', minWidth: 'min-content', align: 'center' },
    { id: 'reports', label: 'Reports', minWidth: 'min-content', align: 'right' }
  ];

  const rows = props.list.map((fund: Fund) => ({
    id: fund._id,
    value: (checkRoles(['dd-viewer'], keycloak) ? columns :
      checkRoles(['fund-center-user', 'report-viewer-all'], keycloak) ? columnsOnlyFundCenter :
        columns).map(column => (
      <>
        {column.id === 'fundName' &&
          <Typography>
            <SecureDisplay
              permissions='details'
              optionalChild={<>{fund.name}</>}
            >
              <EntityLink type='fund' id={fund._id} name={fund.name} center={column.align === 'center'} openInNewTabIcon />
            </SecureDisplay>
          </Typography>
        }
        {column.id === 'assetMgrName' && fund.assetMgr &&
          <Typography>
            <SecureDisplay
              permissions='details'
              optionalChild={<>{fund.assetMgr.name}</>}
            >
              <EntityLink type='assetMgr' id={fund.assetMgr._id} name={fund.assetMgr.name} center={column.align === 'center'} openInNewTabIcon />
            </SecureDisplay>
          </Typography>
        }
        {column.id === 'latestDdType' && fund.latestDdInfo &&
          <Typography>
            <SecureDisplay
              permissions='details'
              optionalChild={<>{fund.latestDdInfo.type}</>}
            >
              <EntityLink type='dueDiligence' id={fund.latestDdInfo._id} name={fund.latestDdInfo.type} center={column.align === 'center'} openInNewTabIcon />
            </SecureDisplay>
          </Typography>
        }
        {column.id === 'gpsl' &&
          <TagIcon val={!!fund.tags?.find(tag => tag.value === 'gpsl')} />
        }
        {column.id === 'validationDate' && fund.latestDdInfo &&
          <Typography>
            {fund.latestDdInfo.validationDate}
          </Typography>
        }
        {column.id === 'nextReviews' &&
          <NextReviews nextReviews={fund.nextReviews} displayMinimalist reviewsStopped={fund.hasReviewsStopped}/>
        }
        {allReviewType.map((reviewType) => {
          const associatedReview = fund.nextReviews?.find(id => id.reviewType === reviewType);

          if (column.id === reviewType) {
            return (
              <NextReviews nextReviews={associatedReview ? [associatedReview] : []} displayMinimalist reviewsStopped={fund.hasReviewsStopped}/>
            );
          }
          
          return null;
        })}
        {/* column.id === 'assetClass' && fund.assetClass }
        {column.id === 'geography' && fund.geography }
        {column.id === 'subAssetClass' && fund.subAssetClass */}
        {column.id === 'rating1' &&
              <div className={classes.rating} >
                <Rating1 ratings={fund.ratings} />
              </div>
        }
        {column.id === 'rating2' &&
              <div className={classes.rating} >
                <Rating2 ratings={fund.ratings} />
              </div>
        }
        {column.id === 'reports' && fund.reportDocuments &&
          <>
            <CustomIconButton variant='contained' onClick={e => handleClick(e, fund._id)} small>
              <GetAppOutlinedIcon />
            </CustomIconButton>
            <StyledMenu
              disableScrollLock
              anchorEl={anchorEl}
              id='reportMenu'
              keepMounted
              open={fund._id === dlOpen}
              onClose={handleClose}
            >
              <Grid container spacing={1}>
                {fund.reportDocuments.map(report => (
                  report.tags.find(tag => tag.value === 'summary' || tag.value === 'mdd-review' || tag.value === 'mdd-analysis') &&
                  <Grid item xs={12} key={report._id}>
                    <MenuItem
                      className={menuClasses.menuItem}
                      onClick={() => {
                        ReportHelper.downloadPDFReportByDocId(report._id);
                        handleClose;
                      }}
                    >
                      {capitalizeFirstLetter(report.tags.find(tag => tag.family === 'ddReportType')?.value)}
                    </MenuItem>
                  </Grid>
                ))}
              </Grid>
            </StyledMenu>
          </>
        }
      </>
    ))
  }));

  return (
    <SecureDisplay
      permissions='details'
      optionalChild={
        <SecureDisplay
          permissions='reports'
          optionalChild={
            <CustomTable columns={columns} rows={rows} size='medium' paramsSort={props.paramsSort} />
          }
        >
          <CustomTable columns={columnsOnlyFundCenter} rows={rows} size='medium' paramsSort={props.paramsSort} />
        </SecureDisplay>
      }
    >
      <CustomTable columns={columns} rows={rows} size='medium' paramsSort={props.paramsSort} />
    </SecureDisplay>
  );
}

export default FundTable;

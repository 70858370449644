import React, { InputHTMLAttributes, ReactElement } from 'react';
import { Grid, Checkbox } from '@mui/material';
import { AumContributor } from '../../../types/types';
import { roundValue } from '../../../utils/computations';
import CustomTable, { Column } from '../../table/table';
import { AumSelectionContentProps } from './type';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined
}

function AumSelectionTable(props: Omit<AumSelectionContentProps, 'processingErrorsSelectedIds'>) : ReactElement {
  let columns: Array<Column> = [];

  const handleCheckedAumContributors = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const identifier = (event?.target as HTMLInputElement).value;
    const label = (event.currentTarget.querySelector('input') as HTMLInputElement)?.getAttribute('label');

    props.setSelectedIds((prevState) => {
      const alreadyExist = prevState.some(prev => prev.identifier === identifier);

      return (
        !alreadyExist && label ?
          [...prevState, { identifier, label }]
          :
          prevState.filter(prev => prev.identifier !== identifier)
      );
    });
  };

  columns = [
    ...columns,
    { id: 'selected', label: 'Selected', minWidth: 'min-content', align: 'center' },
    { id: 'label', label: 'Label', minWidth: 'min-content', align: 'center' },
    { id: 'identifier', label: 'Identifier', minWidth: 'min-content', align: 'center' },
    { id: 'recordType', label: 'Record Type', minWidth: 'min-content', align: 'center' },
    props.aumService.type === 'assetMgr' || props.aumService.type === 'investMgr' || props.aumService.type === 'manCo'?
      { id: 'amount', label: 'Amount', minWidth: 'min-content', align: 'center' }
      :
      { id: 'code', label: 'Code', minWidth: 'min-content', align: 'center' }
  ];

  const rows = props.data?.map((aumContributor: AumContributor) => ({
    id: aumContributor._id,
    value: columns.map(column => (
      <>
        {column.id === 'selected' &&
          <Checkbox
            value={aumContributor.identifier}
            inputProps={{ 'label': aumContributor.label } as InputProps}
            checked={props.selectedIds.some(obj => obj.identifier?.includes(aumContributor.identifier))}
            onClick={e => handleCheckedAumContributors(e)}
          />
        }
        {column.id === 'label' && aumContributor.label}
        {column.id === 'identifier' && aumContributor.identifier}
        {column.id === 'recordType' && aumContributor.recordType}
        {props.aumService.type === 'assetMgr' || props.aumService.type === 'investMgr' || props.aumService.type === 'manCo' ?
          column.id === 'amount' &&
              <div style={{ fontWeight: 'bold' }}>
                {aumContributor.amount?.amount ? roundValue(aumContributor.amount.amount) : 'N/A'} {aumContributor.amount?.currency}
              </div>
          :
          column.id === 'code' &&
              <div style={{ fontWeight: 'bold' }}>
                {aumContributor.code || 'N/A'}
              </div>
        }
      </>
    ))
  }));
  
  return (
    <Grid container xs={12} height='55.5vh' overflow='scroll'>
      <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} hideScroll />
    </Grid>
  );
}

export default AumSelectionTable;
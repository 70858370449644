import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource
} from 'axios';
import { API } from '../api/api';
import {
  ApiResponse,
  Fund,
  Mandate,
  Nullable,
  Filter,
  PossibleAction,
  VersionProps,
  UploadReturn, PostDocumentReturn
} from '../types/types';
import ServiceError from './errors';

export interface BaseUploadServiceInterface extends BaseServiceInterface{
  getUploadUrl(): string,
  uploadFile(file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void, setCancelSource: ((source: CancelTokenSource) => void)): Promise<ApiResponse<Nullable<UploadReturn | PostDocumentReturn>>>
}

interface BaseServiceInterface {
  getBaseUrl(): string,
  getBaseUrlWithId(id: string, surveyId?: string): string,
  handleResponse<T>(res: AxiosResponse<ApiResponse<Nullable<T>>>): Promise<ApiResponse<Nullable<T>>>
}
export class BaseService implements BaseServiceInterface {
  constructor() {
    // super();
  }

  getBaseUrl(): string {
    throw new Error("Implement this in subclass");
  }

  getBaseUrlWithId(id: string, surveyId?: string): string {
    throw new Error(`Implement this in subclass (${id} ${surveyId}`);
  }

  handleResponse<T>(res: AxiosResponse<ApiResponse<Nullable<T>>>): Promise<ApiResponse<Nullable<T>>> {
    return new Promise<ApiResponse<Nullable<T>>>((resolve, reject) => {
      // First, check the response status
      if (res.status < 200 || res.status > 299) {
        reject(new ServiceError(res.status, 'call failed'));
      }

      if (res.status !== 204 && !res.data) {
        reject(new ServiceError(res.status, 'no data'));
      }

      if (res.data.error) {
        reject(new ServiceError(res.status, 'Server returned an error', res.data.error));
      }

      resolve(res.data);
    });
  }
}

export class BaseUploadService extends BaseService implements BaseUploadServiceInterface {
  getUploadUrl(): string {
    throw new Error("Implement this in subclass");
  }

  uploadFile(file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void, setCancelSource: ((source: CancelTokenSource) => void)): Promise<ApiResponse<Nullable<UploadReturn>>> {
    const axiosCancelTokenSource = axios.CancelToken.source();
    const formData = new FormData();

    setCancelSource(axiosCancelTokenSource);

    if (file.type.length === 0) {
      return new Promise<ApiResponse<Nullable<null>>>((resolve, reject) => {
        reject(new ServiceError(500, 'File has no extension'));
      });
    }

    const config: AxiosRequestConfig = {
      onUploadProgress,
      cancelToken: axiosCancelTokenSource.token,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    formData.append('file', file);

    return API.post(`${this.getUploadUrl()}`, formData, config)
      .then(res => this.handleResponse<UploadReturn>(res));
  }
}

export interface AnyParams {
  [key: string] : unknown
}

export interface PaginatedParams extends AnyParams {
  limit?: number,
  skip?: number
}

export interface FundAndMandateProvider {
  getFunds(investMgrId: string): Promise<ApiResponse<Nullable<Array<Fund>>>>,
  getMandates(investMgrId: string): Promise<ApiResponse<Nullable<Array<Mandate>>>>
}

export class BaseEntityService<Entity> extends BaseUploadService {
  constructor() {
    super();
  }

  getPossibleGroupedActions(): Promise<ApiResponse<Nullable<Array<PossibleAction>>>> {

    return API.get(`${this.getBaseUrl()}/possibleGroupedActions`)
      .then(res => this.handleResponse<Nullable<Array<PossibleAction>>>(res));
  }

  makeGroupedActions(selected: Entity[], call: PossibleAction['call']): Promise<ApiResponse<unknown>> {

    return API.post(`${this.getBaseUrl()}/actions/${call?.action}`, selected)
      .then(res => this.handleResponse<unknown>(res));
  }

  getPossibleActions(id: string): Promise<ApiResponse<Nullable<Array<PossibleAction>>>> {
    return API.get(`${this.getBaseUrl()}/${id}/possibleActions`)
      .then(res => this.handleResponse<Nullable<Array<PossibleAction>>>(res));
  }

  getAllVersions(id: string): Promise<ApiResponse<Nullable<Array<VersionProps>>>> {
    return API.get(`${this.getBaseUrl()}/${id}/allVersions`)
      .then(res => this.handleResponse<Nullable<Array<VersionProps>>>(res));
  }

  makeActions(ddId: string, call: PossibleAction['call']): Promise<ApiResponse<unknown>> {
    return API.post(`${this.getBaseUrl()}/${ddId}/actions/call`, call)
      .then(res => this.handleResponse<unknown>(res));
  }

  getListInfo(): {url: string, defaultParams: AnyParams} {
    return {
      url: this.getBaseUrl(),
      defaultParams: {
      }
    };
  }

  getSearchList(query: string): Promise<ApiResponse<Nullable<Array<string>>>> {
    const info = this.getListInfo();

    return API.get(`${info.url}/search`, { params: { name: query } })
      .then(res => this.handleResponse<Array<string>>(res));
  }

  getEntityList(itemsPerPage?: number, skip?: number, filters?: Array<Filter>): Promise<ApiResponse<Nullable<Array<Entity>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };

    if (itemsPerPage) {
      params.limit = itemsPerPage;
    }

    if (skip) {
      params.skip = skip;
    }

    if (filters) {
      filters.forEach((filter) => {
        if (filter.id !== 'f_singleColsReviews') {
          // Now add 'f_' to the filters so the server knowns how to interpret them
          params[filter.id] = filter.value;
        }
      });
    }

    return API.get(info.url, { params })
      .then(res => this.handleResponse<Array<Entity>>(res));
  }

  getEntity(id: string): Promise<ApiResponse<Nullable<Entity>>> {
    return API.get(`${this.getBaseUrl()}/${id}`)
      .then(res => this.handleResponse<Entity>(res));
  }
}

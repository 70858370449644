import React, { ReactElement } from 'react';
import { Box, Chip, darken, lighten, Tooltip, Typography } from '@mui/material';
import { NextReview } from '@deecision/deeligenz-interfaces';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CrisisAlertRoundedIcon from '@mui/icons-material/CrisisAlertRounded';

export const warningDatesColorMapping: { [key: string]: string } = {
  yellow: '#FFE500',
  orange: '#F58B00',
  red: '#E32626'
};

interface NextReviewChipProps {
  review: NextReview,
  hideTooltipChipDateInformation?: boolean
}

function NextReviewChipDateIconInformation(props: NextReviewChipProps & { warningColor: string | undefined }): ReactElement {
  const date = new Date();

  const calculateDaysDifference = (date1: Date, date2: string) => {
    const targetDate = new Date(date2);
    const diffInTime = targetDate.getTime() - date1.getTime();
    
    return Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
  };

  const daysLeft = calculateDaysDifference(date, props.review.warningDates.orange);
  const daysLate = calculateDaysDifference(new Date(props.review.warningDates.orange), date.toISOString());

  const isLeftCondition =  (props.warningColor === warningDatesColorMapping.yellow ||  !props.warningColor);
  const isLateCondition = (props.warningColor === warningDatesColorMapping.red || props.warningColor === warningDatesColorMapping.orange);

  const defaultChipBgColor = 'lightgrey';
  const chipTypographyDaysStyle = {
    color: props.warningColor ? darken(props.warningColor, 0.5) : undefined,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <Box py={props.hideTooltipChipDateInformation ? 0 : 0.5} justifyContent='center' display='flex' flexDirection='row' gap={1}>
      {isLeftCondition && !props.hideTooltipChipDateInformation &&
        <Chip
          key={props.review.dueDate}
          label={
            <Typography textAlign='center' sx={chipTypographyDaysStyle}>
              <ErrorRoundedIcon fontSize='small' /> &nbsp;{daysLeft} day{daysLeft > 1? 's' : ''} left&nbsp;
            </Typography>
          }
          style={{ backgroundColor: props.warningColor? lighten(props.warningColor, 0.7 ) : defaultChipBgColor }}
          size='small'
        />
      }
      {isLateCondition && !props.hideTooltipChipDateInformation &&
        <Chip
          key={props.review.dueDate}
          label={
            <Typography textAlign='center' sx={chipTypographyDaysStyle}>
              <CrisisAlertRoundedIcon fontSize='small' /> &nbsp;{daysLate} day{daysLate > 1? 's' : ''} late&nbsp;
            </Typography>
          }
          style={{ backgroundColor: props.warningColor? lighten(props.warningColor, 0.7 ) : defaultChipBgColor }}
          size='small'
        />
      }
      <Typography textAlign='center'>
        {props.review.reviewType}{props.review.ddTypes.length > 0 ? ` (${props.review.ddTypes[0]})` : ''}
      </Typography>
    </Box>
  );
}

function NextReviewChip(props: NextReviewChipProps): ReactElement {
  const date = new Date();
  
  const determinateColorFromWarningDates = (
    warningDates: {
        yellow: string,
        orange: string,
        red: string
      }
  ) => {
    const yellowDate = new Date(warningDates.yellow);
    const orangeDate = new Date(warningDates.orange);
    const redDate = new Date(warningDates.red);
    
    if (date < yellowDate) {
      return undefined; // use default color
    }
    if (date >= yellowDate && date < orangeDate) {
      return warningDatesColorMapping.yellow;
    }
    if (date >= orangeDate && date < redDate) {
      return warningDatesColorMapping.orange;
    }
  
    return warningDatesColorMapping.red;
  };

  const warningColor = determinateColorFromWarningDates(props.review.warningDates);

  return (
    <Tooltip
      arrow
      title={
        <NextReviewChipDateIconInformation {...props} warningColor={warningColor} />
      }>
      <Chip
        key={props.review.dueDate}
        label={
          <Typography color={warningColor ? darken(warningColor, 0.5) : undefined} fontWeight={600}>
            {props.review.dueDate}
          </Typography>
        }
        style={{ backgroundColor: warningColor? lighten(warningColor, 0.7 ) : undefined }}
        size='small'
      />
    </Tooltip>
  );
}

export default NextReviewChip;
import React, { ReactElement } from 'react';
import { ddAumContext } from '../../../hooks/datatabs';
import JsonWrapper from '../../dev/jsonwrapper';

function DdAumJson(): ReactElement {
  const aum = ddAumContext();

  return (
    <>
      { aum &&
        <JsonWrapper>
          {JSON.stringify(aum, null, ' ')}
        </JsonWrapper>
      }
    </>
  );
}

export default DdAumJson;
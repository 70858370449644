import React, { ReactElement, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Grid, Snackbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DdElement } from '@deecision/deeligenz-interfaces';
import { DdDocument } from '../../../types/types';
import ServiceError from '../../../services/errors';
import { DdElementService, DdElementsService } from '../../../services/ddelements';
import AnalysisDdElementListItem, { useStyles } from '../../../components/ddelement/listitem';

function DdAnalysisDocumentList(props: {list: Array<DdDocument>, ddId: string}): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const ddElementService = new DdElementService<DdElement>();
  const ddElementsService = new DdElementsService<DdElement>();
  const [update, setUpdate] = useState(true);
  const [answersWithDoc, setAnswersWithDoc] = useState<DdElement[]>([]);
  const [answersWithoutDoc, setAnswersWithoutDoc] = useState<DdElement[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (update) {
      props.list.map(doc => doc.entityLinks.find(e => e.type === 'ddElement')?._id).forEach((ddElementId) => {
        ddElementId && ddElementService.get(ddElementId)
          .then((apiResp) => {
            if (apiResp.data) {
              setAnswersWithDoc((prevState) => {
                const newData = apiResp.data as DdElement;
                // We need to remove duplicate _id for questions that have file[] because multiple file uploaded in the same id will duplicate the questions in analysis
                const filteredState = prevState.filter(item => item._id !== newData._id);
              
                return [...filteredState, newData];
              });
            }
          })
          .catch((err) => {
            setErrorMsg(ServiceError.getErrorMsg(err));
          });
        setUpdate(false);
      });
    }
  }, [props.list, update]);

  useEffect(() => {
    ddElementsService.getElementList(props.ddId, undefined, undefined, undefined, true)
      .then((apiResp) => {
        if (apiResp.data) {
          setAnswersWithoutDoc(apiResp.data);
        }
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
      });
  }, []);

  return (
    <>
      <Grid container spacing={2} direction='column'>
        <Grid item>
          <Accordion elevation={0} className={classes.paper}>
            <AccordionSummary
              style={{ flexDirection: 'row-reverse' }}
              expandIcon={<ExpandMoreRoundedIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='h1' style={{ marginLeft: '12px' }}>{t('dds.tabs.documents.analysisDocs')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} direction='column'>
                {answersWithDoc.map((answerWithDoc) => {
                  const answer = answerWithDoc.answers.auditor && answerWithDoc.answers.auditor.value ?
                    answerWithDoc.answers.auditor :
                    (answerWithDoc.answers.auditee && !answerWithDoc.answers.auditee.draft && answerWithDoc.answers.auditee) || undefined;

                  if (!answer) {
                    return undefined;
                  }

                  return (
                    <Grid item key={answerWithDoc._id}>
                      <AnalysisDdElementListItem ddElement={answerWithDoc} setUpdateList={() => setUpdate(true)} readonly='full' lightDisp />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion elevation={0} className={classes.paper} style={{ backgroundColor: 'rgba(229, 57, 53, 0.175)' }}>
            <AccordionSummary
              style={{ flexDirection: 'row-reverse' }}
              expandIcon={<ExpandMoreRoundedIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='h1' style={{ marginLeft: '12px' }}>{t('dds.tabs.documents.analysisMissingDocs')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} direction='column'>
                {answersWithoutDoc.map(answerWithoutDoc => (
                  <Grid item key={answerWithoutDoc._id}>
                    <AnalysisDdElementListItem ddElement={answerWithoutDoc} setUpdateList={() => setUpdate(true)} readonly='full' lightDisp />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DdAnalysisDocumentList;

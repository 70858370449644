import { ActionLog, ApiResponse, Filter, Nullable } from '@deecision/deeligenz-interfaces';
import { API } from '../api/api';
import { AnyParams, BaseEntityService, PaginatedParams } from './data';

class AuditTrailService extends BaseEntityService<ActionLog> {
  entityType: string;

  _id: string;

  constructor(entityType: string, id: string) {
    super();
    this.entityType = entityType;
    this._id = id;
  }

  getId(): string {
    return this._id;
  }
  
  getBaseUrl(): string {
    return `/api/v1/auditedentities/${this.entityType}/${this._id}`;
  }

  getListInfo(): {url: string, defaultParams: AnyParams} {
    return {
      url: this.getBaseUrl(),
      defaultParams: {}
    };
  }

  getEntityList(itemsPerPage?: number, skip?: number, filters?: Array<Filter>): Promise<ApiResponse<Nullable<Array<ActionLog>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };
  
    if (itemsPerPage) {
      params.pageSize = itemsPerPage;
    }
  
    if (skip) {
      const adaptSkipToPage = skip / (itemsPerPage || 20);

      params.page = adaptSkipToPage;
    }
  
    if (filters) {
      filters.forEach((filter) => {
        // Now add 'f_' to the filters so the server knowns how to interpret them
        params[filter.id] = filter.value;
        if (filter.id === 'f_name') {
          params.page = 0;
        }
      });
    }

    return API.get(`${this.getBaseUrl()}/events`, { params })
      .then(res => this.handleResponse<Array<ActionLog>>(res));
  }
  
  // getEntity(id: string): Promise<ApiResponse<Nullable<ActionLog>>> {
  //   return API.get(`${this.getBaseUrl()}/events`)
  //     .then(res => this.handleResponse<ActionLog>(res));
  // }

}

export default AuditTrailService;
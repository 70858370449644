import { upperFirst } from "lodash";
import { Column } from "../table/table";

const allReviewType = [
  'major',
  'minor',
  'odd'
];

export const multipleReviewsColumns: Array<Column> = allReviewType.map(review => (
  { id: review, label: `${upperFirst(review)} Review Date`, minWidth: 'min-content', align: 'center' }
));

export default allReviewType;
import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table  from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import CustomHead from './head';
import CustomBody from './body';
import { FilterParamsManager } from '../entity/list';

export interface Column {
  id?: string,
  label: string | ReactElement | null,
  minWidth: string,
  align?: 'center' | 'left' | 'right'
}

export interface Row {
  id: string,
  value: Array<ReactElement>,
  style?: React.CSSProperties,
  className?: string
}

interface PropsType {
  columns: Array<Column>,
  rows: Array<Row> | undefined,
  size?: 'small' | 'medium' | undefined,
  paramsSort?: FilterParamsManager,
  hideScroll?: boolean,
  nonScrollableY?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  container: {
    width: 'calc(100% + 48px)',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'visible',
    maxHeight: 'calc(100vh - 56px - 33px - 54px - 38px - 28px)',
    padding: '0px 24px 24px 24px',
    margin: '-8px 0px 0px -24px'
  },
  containerHiddenScroll: {
    scrollbarWidth: 'none', // hide scrollBar on Firefox
    '&::-webkit-scrollbar': {
      display: 'none' // hide scrollBar on Chrome and Safari
    }
  },
  containerWithoutScroll: {
    width: 'calc(100% + 48px)',
    alignItems: 'center',
    overflowX: 'visible',
    padding: '0px 24px 24px 24px',
    margin: '-8px 0px 0px -24px'
  },
  table: {
    marginTop: '-8px',
    borderSpacing: '0px 8px',
    borderCollapse: 'separate',
    backgroundColor: 'transparent',
    overflow: 'visible'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

function CustomTable(props: PropsType): ReactElement {
  const { columns, rows, size } = props;
  const classes = useStyles();

  return (
    <TableContainer className={`${props.nonScrollableY ? classes.containerWithoutScroll : classes.container} ${props.hideScroll ? classes.containerHiddenScroll : ''}`}>
      <Table stickyHeader size={size} aria-label='sticky table' className={classes.table} >
        {CustomHead(columns, props.paramsSort)}
        {CustomBody({ rows, columns })}
      </Table>
    </TableContainer>
  );
}

export default CustomTable;

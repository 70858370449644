import { AxiosRequestConfig } from 'axios';
import { API } from '../api/api';
import { ApiResponse, Filter, Nullable } from '../types/types';
import { AnyParams, BaseService } from './data';

type DdElementsServiceReturnType<T> = Promise<ApiResponse<Nullable<Array<T>>>>;
type DdElementServiceReturnType<T> = Promise<ApiResponse<Nullable<T>>>;

export abstract class AbstractDdElementsService<T> extends BaseService{
  basePath: string;

  protected constructor() {
    super ();
    this.basePath = '';
  }

  abstract getBaseUrlWithId(id: string, surveyId?: string): string;

  abstract get(id: string, config?: AxiosRequestConfig): DdElementsServiceReturnType<T> | DdElementServiceReturnType<T>;
}

export interface SearchElementService<T> {
  getElementList(ddId: string, filters?: Array<Filter>, skip?: number, limit?: number): Promise<ApiResponse<Nullable<Array<T>>>>
}

export class DdElementsService<T> extends AbstractDdElementsService<T> implements SearchElementService<T> {
  constructor() {
    super();
  }

  getBaseUrlWithId(id: string): string {
    this.basePath = `/api/v1/dds/${id}/elements?active=true`;

    return this.basePath;
  }

  get(id: string, config?: AxiosRequestConfig): DdElementsServiceReturnType<T> {
    return API.get(this.getBaseUrlWithId(id), config);
  }

  getElementList(ddId: string, filters?: Array<Filter>, skip?: number, limit?: number, missingFilesOnly?: boolean) : Promise<ApiResponse<Nullable<Array<T>>>> {
    const filtersParams: AnyParams = {};

    if (filters) {
      filters.forEach((filter) => {
        const filterValue = filter.value.split(',');
        // Now add 'f_' to the filters so the server knowns how to interpret them
        filtersParams[filter.id] = filterValue.length > 1 ? filterValue : filter.value;
      });
    }
    const text = filtersParams.text;
    delete filtersParams.text;

    const body = {
      filters: filtersParams,
      text,
      skip,
      limit
    };
    filtersParams;
    let url = `/api/v1/dds/${ddId}/elements/search`;

    if (missingFilesOnly) {
      url = `/api/v1/dds/${ddId}/elements?missingFilesOnly=true`;
    
      return API.get(url)
        .then(res => this.handleResponse<Array<T>>(res));
    }

    return API.post(url, body)
      .then(res => this.handleResponse<Array<T>>(res));
    // TODO: here, handle the normal errors instead of returning the Promise from AXIOS
  }
}

export class DdElementService<T> extends AbstractDdElementsService<T> {
  constructor() {
    super();
  }

  get(id: string, config?: AxiosRequestConfig): DdElementServiceReturnType<T> {
    return API.get(this.getBaseUrlWithId(id), config)
      .then(res => this.handleResponse<T>(res));
  }

  getBaseUrlWithId(id: string): string {
    this.basePath = `/api/v1/ddelements/${id}`;

    return this.basePath;
  }
}

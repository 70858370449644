import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
import { ActionType } from '@deecision/deeligenz-interfaces';

const successBiasColor = '#22BF9A';
const negativeBiasColor = '#D40C1A';
const neutralBiasColor = '#0D64BA';

export type ColorPalette = 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export const colorToPalette = {
  [successBiasColor]: 'success',
  [negativeBiasColor]: 'error',
  [neutralBiasColor]: 'info'
};

export enum ActionTypeColor {
  CREATE = successBiasColor,
  DELETE = negativeBiasColor,
  START = successBiasColor,
  CLOSE = negativeBiasColor,
  STOP = neutralBiasColor,
  PAUSE = neutralBiasColor,
  WATCH = neutralBiasColor,
  UNWATCH = neutralBiasColor,
  VALIDATE = successBiasColor,
  REJECT = negativeBiasColor,
  APPROVE = successBiasColor,
  REQUEST = neutralBiasColor,
  RESPONSE = neutralBiasColor,
  COMPLETED = successBiasColor,
  CANCELLED = negativeBiasColor,
  UPDATE = neutralBiasColor
}

export const ActionTypeIconComponents = {
  [ActionType.CREATE]: AddRoundedIcon,
  [ActionType.DELETE]: DeleteRoundedIcon,
  [ActionType.START]: PlayCircleOutlineRoundedIcon,
  [ActionType.CLOSE]: CloseRoundedIcon,
  [ActionType.STOP]: StopCircleRoundedIcon,
  [ActionType.PAUSE]: PauseCircleFilledRoundedIcon,
  [ActionType.WATCH]: VisibilityRoundedIcon,
  [ActionType.UNWATCH]: VisibilityOffRoundedIcon,
  [ActionType.VALIDATE]: DoneRoundedIcon,
  [ActionType.REJECT]: ThumbDownAltRoundedIcon,
  [ActionType.APPROVE]: ThumbUpAltRoundedIcon,
  [ActionType.REQUEST]: ConnectWithoutContactRoundedIcon,
  [ActionType.RESPONSE]: QuestionAnswerRoundedIcon,
  [ActionType.COMPLETED]: TaskAltRoundedIcon,
  [ActionType.CANCELLED]: StopCircleRoundedIcon,
  [ActionType.UPDATE]: EditRoundedIcon
};
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../components/params';
import { Fund } from '../../../types/types';
import { FundTable } from './table';
import { EntityListDataProvider, ListRendererMap, MultiModeList } from '../../../components/entity/list';
import { FundService } from '../../../services/fund';
import FundFilters from './filters';
import CustomPagination from '../../../components/layout/pagination/pagination';
import TableSkeleton from '../../../components/layout/loading/tableskeleton';

class FundListDataProvider extends EntityListDataProvider<Fund> {
  constructor(searchParamsAccess: SearchParamsAccess) {
    super(searchParamsAccess, new FundService());
  }
}

export function FundMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new FundListDataProvider(searchParamsAccess);
  let singleReviewsColumns = true;

  singleReviewsColumns = searchParamsAccess.getParam('f_singleColsReviews') === 'true';

  if (searchParamsAccess.getParam('f_singleColsReviews') === null) {
    searchParamsAccess.updateParam('f_singleColsReviews', 'true');
    singleReviewsColumns = true;
  }

  function renderEmpty() {
    return (
      <div style={{ marginTop: '40px' }}>No fund...</div>
    );
  }

  function renderLoading() {
    return (
      <TableSkeleton nbColumns={7} />
    );
  }

  function renderAsTable(eltList: Array<Fund>, totalCount: number) {
    return (
      <>
        <FundTable list={eltList} paramsSort={listDataProvider.sortParamsManager} singleReviewCol={singleReviewsColumns}/>
        <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      </>
    );
  }

  function renderAsCards(eltList: Array<Fund>, totalCount: number) {  // eslint-disable-line @typescript-eslint/no-unused-vars
    return (
      <>
        <div>Cards List here</div>
        <div>Pagination goes here...</div>
      </>
    );
  }

  const renderers: ListRendererMap<Fund> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' /* 'cards' */]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={() => renderEmpty()}
      renderLoading={() => renderLoading()}
      filtersComponent={FundFilters}
    />
  );
}

function FundListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <FundMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default FundListingPage;

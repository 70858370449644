import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import Aums from '../../../../components/aum/aums';
import { fundContext, setUpdateHook } from '../../../../hooks/datatabs';
import AuditedEntitiesService from '../../../../services/auditedentities';
import NextReviewParameters from '../../../../components/nextreviews/nextreviewparameters';

function Parameters(): ReactElement {
  const entity = fundContext();
  const update = setUpdateHook();
  const entityService = entity ? new AuditedEntitiesService('fund', entity._id) : undefined;

  return (
    <>
      { entity && entityService &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Aums name={entity.name} aumService={entityService} aum={entity.aum} update={() => update(true)} entity />
          </Grid>
          <Grid item xs={12}>
            <NextReviewParameters nextReviews={entity.nextReviews} service={entityService} update={() => update(true)} />
          </Grid>
        </Grid>
      }
    </>
  );
}

export default Parameters;

import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AumImport } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import { createContext } from './context';
import {
  DueDiligenceParsed,
  InvestMgr,
  Nullable,
  Fund,
  Mandate,
  Strategy,
  DataSurvey,
  DdTemplate,
  DdReportBlock,
  SettingsContext
} from '../types/types';
import { pathContext } from './path';
import { DdElementGroupType } from '../types/importtypes';

export const [dataSettings, DataContextSettings] = createContext<Nullable<SettingsContext>>();
export const [dataContext, DataContextProvider] = createContext<Nullable<DueDiligenceParsed>>();
export const [fundContext, FundContextProvider] = createContext<Nullable<Fund>>();
export const [investmgrContext, InvestmgrContextProvider] = createContext<Nullable<InvestMgr>>();
export const [mandateContext, MandateContextProvider] = createContext<Nullable<Mandate>>();
export const [strategyContext, StrategyContextProvider] = createContext<Nullable<Strategy>>();
export const [surveyContext, SurveyContextProvider] = createContext<Nullable<DataSurvey>>();
export const [ddElementGroupContext, DdElementGroupProvider] = createContext<Nullable<DdElementGroupType>>();
export const [ddTemplateContext, DdTemplateProvider] = createContext<Nullable<DdTemplate>>();
export const [ddAumContext, DdAumProvider] = createContext<Nullable<AumImport>>();
export const [ddReportBlockContext, DdReportBlockProvider] = createContext<Nullable<DdReportBlock>>();
export const [sidebarStatus, SidebarStatusProvider] = createContext<Nullable<boolean>>();
export const [setUpdateHook, SetUpdateHookContextProvider] = createContext<Dispatch<SetStateAction<boolean>>>();

export function PathHook(pathName: string): void {
  const [pathId, setPathId]  = pathContext();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    !pathName.includes('undefined') && id && (pathId[0] === '' || pathId[0] !== id) && setPathId([id, pathName]);
  });
}

import React, { createContext, ReactElement, useContext, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Box, darken, Grid, Tooltip } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useTranslation } from 'react-i18next';

interface Entity {
  type: string,
  path: string
}

interface EntityLinkProps {
  type: string,
  id?: string,
  name?: string,
  params?: string,
  className?: string,
  children?: ReactElement | Array<ReactElement> | string,
  reload?: boolean,
  noUnderline?: boolean,
  target?: string,
  openInNewTabIcon?: boolean,
  center?: boolean,
  iconMargin?: string,
  disabled?: boolean
}

const entities: Array<Entity> = [
  { type: 'root', path: '' },
  { type: 'fund', path: 'funds' },
  { type: 'assetMgr', path: 'assetmgrs' },
  { type: 'investMgr', path: 'mancos' },
  { type: 'due diligence', path: 'dds' }, // DEPRECATED
  { type: 'dueDiligence', path: 'dds' },
  { type: 'mandate', path: 'mandates' },
  { type: 'strategy', path: 'strategies' },
  { type: 'auditee', path: 'auditee/surveys' },
  { type: 'survey', path: 'auditee/surveys' },
  { type: 'admin', path: 'admin' },
  { type: 'import', path: 'admin/import' },
  { type: 'imports', path: 'admin/imports' },
  { type: 'importGroups', path: 'admin/importgroups' },
  { type: 'importTemplate', path: 'admin/import/template' },
  { type: 'importGPSL', path: 'admin/import/gpsl' },
  { type: 'importAUM', path: 'admin/import/aum' },
  { type: 'ddElementGroups', path: 'admin/ddelementgroups' },
  { type: 'ddTemplates', path: 'admin/ddtemplates' },
  { type: 'settings', path: 'admin/settings' }
];

function getTypeInfo(type: string) {
  return entities.find(e => e.type === type);
}

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  linkWithoutUnderline: {
    textDecoration: 'none',
    color: 'inherit'
  }
});

function EntityLink(props: EntityLinkProps): ReactElement {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const setPathId = useContext(createContext<[Array<string>, React.Dispatch<React.SetStateAction<Array<string>>>]>(useState(['', ''])))[1];
  const typeInfo = getTypeInfo(props.type);
  const [mouseIn, setMouseIn] = useState(false);

  const handleCleanReload = () => {
    if (typeInfo) {
      navigate({ pathname: `/${typeInfo.path}`, search: '' });
    } else {
      navigate({ search: '' });
    }
    if (pathname.split('/').length < 3) {
      window.location.reload();
    }
  };

  if (props.reload) {
    return (
      <Box onClick={handleCleanReload}>
        {props.name}
        {props.children}
      </Box>
    );
  }

  return (
    <>
      { typeInfo && !props.disabled ?
        <Grid
          container
          style={{ width: '100% !important', marginTop: '6px' }}
          justifyContent={props.center ? 'center' : 'flex-start'}
          onMouseEnter={() => setMouseIn(true)}
          onMouseLeave={() => setMouseIn(false)}
          onMouseOut={() => setMouseIn(false)}
          onMouseOver={() => setMouseIn(true)}
        >
          <Grid item xs={!props.openInNewTabIcon}>
            <NavLink to={`/${typeInfo.path}/${props.id || ''}${props.params || ''}`} target={props.target} className={props.className || props.noUnderline? classes.linkWithoutUnderline : classes.link} onClick={() => setPathId(['', ''])}>
              {props.name}
              {props.children}
            </NavLink>
          </Grid>
          {props.openInNewTabIcon &&
            <Grid item xs={!props.center} style={{ textAlign: 'start', opacity: mouseIn ? '1' : '0', marginRight: '-14.5px' }}>
              <NavLink to={`/${typeInfo.path}/${props.id || ''}${props.params || ''}`} target='_blank'>
                <Tooltip title={t('utils.openInNewTab')} arrow>
                  <OpenInNewRoundedIcon
                    style={{
                      marginTop: '-1px',
                      marginLeft: '2px',
                      margin: props.iconMargin,
                      transform: 'scale(0.6)',
                      cursor: 'pointer',
                      color: darken('#fff', 0.4)
                    }}
                  />
                </Tooltip>
              </NavLink>
            </Grid>
          }
        </Grid>:
        <>
          {props.name}
          {props.children}
        </>
      }
    </>
  );
}

export default EntityLink;

import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AmountFieldViewer from '../../field/viewers/amountfieldviewer';
import { AmountType, Aum } from '../../../types/types';
import HeaderPropertyName from './headerpropertyname';
import HeaderPropertyValue from './headerpropertyvalue';

function HeaderAUM({ aum }: {aum?: Aum}): ReactElement {
  const { t } = useTranslation();

  return (
    // <Typography style={{ marginLeft: '40px', marginTop: '-8px', marginBottom: '12px' }}>AUM : {entity.auditedEntity.aum.amount.amount}€</Typography>
    <>
      <HeaderPropertyName name={t('dds.header.blocks.aum')} />
      { aum?.amount?.amount ?
        <Grid item xs style={{ marginTop: '-2px' }}>
          <AmountFieldViewer value={aum.amount as AmountType} trunc fontWeight={500}/>
        </Grid>
        :
        aum?.code ?
          <HeaderPropertyValue>{aum.code}</HeaderPropertyValue>
          :
          <HeaderPropertyValue>{t('utils.notApplicable')}</HeaderPropertyValue>
      }
    </>
  );
}

export default HeaderAUM;
